/* Helpline.css */

.helpline-container {
  max-width: 650px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.helpline-info {

  font-size: 16px;
}

.helpline-info p {
  margin: 5px 0;
}

p.fs14 {
  font-size: 16px;
  color: #3e3e3e;
  line-height: 30px;
}

.wrapper_helpline h3 {
  font-size: 22px;
  font-weight: 600;
  color: #2f29a4;
}

.textp p {
  font-weight: 400;
}

.img-fluid2 {
  max-width: 100%;
  height: 218px;
  object-fit: contain;
}

/* Styling for the heading */
h1 {
  text-align: center;
  color: #333;
}

.textp {
  font-size: 14px;
  font-weight: 600;
}

.textp i {
  width: 25px;
  height: 25px;
  color: #fff !important;
  background: #2f29a4;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  margin-right: 5px;
}