.thankuText {
    font-family: 'Pacifico', cursive;
    font-size: 51px;
    line-height: normal;
    color: #2f29a4;
}

.mainThanku {
    display: flex;
    align-items: center;
    height: 100vh;
}