.maininutcls i.fa.fa-times {
    position: absolute;
    left: 2rem;
    top: 2rem;
    background: #ffffffcf;
    border-radius: 29px;
    width: 19px;
    height: 18px;
    display: none;
    z-index: 9999;
}

.phoneInput>*:first-child {
    width: 60px;
    margin-right: 10px;
}

.phoneInput {
    display: flex;
    justify-content: space-between;
}

.maininutcls:hover .fa.fa-times {
    display: inline-block;
}

.maininutcls {
    display: inline-block;
    position: relative;

}

.maininutcls {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.profile_btn {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.start {
    color: red;
}

.form-control:disabled,
.form-control[readonly] {
    background-color: #D0D5DD;
    opacity: 1;
}

// i.fa.fa-search {
//     display: none;
// }

// .headerSearch .Searchbar {
//     background: #FFFFFF;
//     border-radius: 100px;
//     border: none;
//     padding: 10px 35px;
//     display: none;
// }
.inputFlex {
    display: flex;
    column-gap: 10px;

    >label {
        width: 200px;
        font-weight: 500;
        margin-bottom: 0px;
    }

    >div {
        width: 100%;
        font-weight: 300;
    }

}



.editprofile-list {
    background: rgb(240, 240, 240);
    padding: 8px;
    border-radius: 50px;
    box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;

}


.editprofile-list img {
    border-radius: 50px;
    height: 70px;
    width: 70px;
    object-fit: contain;
}


// changes

@media only screen and (min-width:694px) and (max-width:730px) {
    .profile_btn {
        margin-top: 15px;
    }

    label.profileImageLabel {
        margin-right: 15px;
    }

    label.btn.btn-primary.edit.ml-3 {
        margin-left: 0rem !important;
    }
}

@media only screen and (min-width:596px) and (max-width:693px) {

    .profile_btn {
        margin-top: 15px;
    }

    label.btn.btn-primary.edit.ml-3 {
        margin-left: 0px !important;
    }
}

.inputFlex>label {
    font-weight: 600;
}

@media only screen and (min-width:548px) and (max-width:595px) {

    .profile_btn {
        margin-top: 15px;
    }

    label.btn.btn-primary.edit.ml-3 {
        margin-left: 0px !important;
        margin-right: 15px !important;
    }

    label.btn.btn-primary.delete.ml-3 {
        margin-left: 0px !important;
    }

    .inputFlex>label {
        width: 150px;
    }

    .ViewUser {
        font-size: 20px;
    }
}

@media only screen and (min-width:384px) and (max-width:547px) {
    .profile_btn {
        margin-top: 15px;
    }

    label.btn.btn-primary.edit.ml-3 {
        margin-left: 0px !important;
    }

    label.btn.btn-primary.delete.ml-3 {
        margin-left: 0px !important;
    }

    .inputFlex>label {
        width: 150px;
    }

    .ViewUser {
        font-size: 20px !important;
    }
}

@media only screen and (min-width:320px)and (max-width:383px) {
    .profile_btn {
        margin-top: 15px;
    }

    label.btn.btn-primary.edit.ml-3 {
        margin-left: 0px !important;
    }

    label.btn.btn-primary.delete.ml-3 {
        margin-left: 0px !important;
    }

    .inputFlex>label {
        width: 100px;
    }

    .ViewUser {
        font-size: 20px !important;
    }

}