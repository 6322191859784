.profileImage {
    width: 72px;
    height: 72px;
    border-radius: 50%;
    object-fit: contain;
}

.profileImageLabel {
    margin: 0;
    position: relative;

    >i {
        position: absolute;
        bottom: -3px;
        right: -3px;
    }
}

.pprofile1 {
    padding: 21px 22px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    background-color: #fff;
    border-radius: 12px;
}

p.bg-light.rounded.py-2.fieldcls {
    background-color: #2f29a42b !important;

}

.ViewUser {
    // font-weight: 500;
    color: #202020;
    font-weight: 600;
    font-size: 36px;
    margin-bottom: 32px;
}


.border_layout {
    padding: 20px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.listimgpro img {
    border-radius: 50%;
    height: 150px;
    width: 150px;
    object-fit: contain;
    box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
}

a.btn.btn-primary.reset:hover {
    color: #000 !important;
}