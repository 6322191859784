.profileImage {
    width: 72px;
    height: 72px;
    border-radius: 50%;
}

.chip {
    background: #765aa6 !important;
}

.profileImageLabel {
    margin: 0;
    position: relative;

    >i {
        position: absolute;
        bottom: -3px;
        right: -3px;
    }
}

.pprofile1 {
    padding: 21px 22px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    background-color: #fff;
    border-radius: 12px;
}

p.bg-light.rounded.py-2.fieldcls {
    background-color: #2f29a42b !important;

}

.ViewUser {
    // font-weight: 500;
    color: #202020;
    font-weight: 600;
    font-size: 36px;
    margin-bottom: 32px;
}

// new company designs
h2.mb-5 {
    font-size: 24px;
    margin-bottom: 20px !important;
}

h3.company_heading {
    font-size: 16px;
    font-weight: 600;
    color: #2C6DEB;
    margin-bottom: 1rem;
    margin-top: 0.5rem;
}

.company_cards {
    padding: 0rem;
    background: white;
    border-radius: 12px;
    // margin: 2rem auto;
    width: 100%;
    height: auto;
    margin-top: 1rem;
}

hr.company_line {
    border-top: 1px solid #202020;
    margin: 0px !important;
}

.info_detail {
    padding: 1rem;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
}

a.Edit_section {
    margin: 0px;
    font-size: 14px;
    font-weight: 400;
    color: #2f29a4;

    &:hover {
        text-decoration: none;
    }
}

.company_detail {
    display: flex;
    align-items: center;
    height: 100%;
    // min-height: 70px;

}

p.company_details {
    margin-bottom: 8px !important;
    font-size: 14px;
    font-weight: 400;
    color: #84818A;
}

h4.company_name {
    margin-bottom: 0px !important;
    font-size: 14px;
    font-weight: 400;
    color: #6B7280;
    background: #fff;
    padding: 10px 10px;
    border: 1px solid #e9e9e9;
    border-radius: 4px;
}

span.comapny_logos {
    background: #DCDCDC;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

label.user_company {
    margin-left: 0rem;
    font-size: 14px;
}

.searchWrapper {
    border-radius: 8px !important;
}

// edit section css

.edit_company_detail {
    padding: 1.5rem;
    border-top: 1px solid #2020204d;
}

i.material-icons.calls {
    font-size: 16px;
}

.table thead th {
    font-size: 14px !important;
}

.phoneInput>*:first-child {
    width: 100% !important;
    margin-right: 0px !important;
}

.react-tel-input .form-control {
    width: 100% !important;
    height: 38px;
}

h1.info_heading {
    font-size: 16px;
    font-weight: 600;
    color: #3C3E49;
}

h1.info_heading {
    font-size: 16px;
    font-weight: 600;
    color: #3C3E49;
    margin: 2rem 0px;
}

.light_white {
    border: 1px solid #dcdcdc;
    background: #EAECF0;
}

.light_white:hover {
    border: 1px solid #dcdcdc;
    background: #EAECF0;
    color: #212529;
}

span.material-icons.material_edit {
    font-size: 16px;
    margin-right: 7px;
    position: relative;
    top: 2px;
}

h4.btnwhite {
    font-size: 16px;
    color: #6956E5;
    margin-bottom: 0px;
    border: 1px solid #dcdcdc;
    padding: 6px 15px;
    border-radius: 8px;
    cursor: pointer;
}