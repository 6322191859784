.react-datepicker-wrapper {
    width: 100%;
}

.removeIc {
    cursor: pointer;
    position: absolute;
    right: 18px;
    background: #f6f6f6;
    border-radius: 8px;
}
.pCls{
    padding: 19px 15px !important;
}
.inputOverlapIcon {
    position: absolute;
    right: 27px;
    top: 2px;
    width: auto;
    text-align: right;
    margin-top: 8px;
    border-left: 1px solid #d0d0d0;
}
 .addIc {
    padding: 7px 10px;
    color: #fff;
    cursor: pointer;
    border-radius: 8px;
    margin-left: auto;
    position: absolute;
    right: 0;
    top: -55px;
}
.pointer{
    cursor: pointer;
}