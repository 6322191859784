@import './mixins';

// Colors
@import './colors';
@import './table';






.sign {
  font-size: 24px !important;

  &.sign1 {
    font-family: 'Meddon', cursive;
  }

  &.sign2 {
    font-family: 'Mr De Haviland', cursive;
  }

  &.sign3 {
    font-family: 'Pacifico', cursive;
  }

  &.sign4 {
    font-family: 'Pinyon Script', cursive;
  }
}


.tox-notifications-container,
.tox-statusbar__branding {
  display: none !important;
}

.charttooptip {
  width: max-content;
  max-width: 300px;
}

.perAmSpan {
  margin: 0 !important;

  &:not(:last-child) {

    &::after {
      content: "/";
      display: inline-block;
      margin: 0 5px;
    }
  }
}

.tooptipWraper {
  position: relative;
  overflow: visible;
  cursor: pointer;

  &:hover {
    .tooptipBox {
      display: block;
      border: 1px solid #797878;
      border-radius: 8px;
    }
  }

}

.tooptipBox {
  display: none;
  position: absolute;
  padding: 6px;
  // box-shadow: 10px 0 4px 0px #00000038;
  top: 100%;
  background-color: #fff;
  z-index: 9999;

  &:hover {
    display: none !important;
  }
}

body {
  font-size: 14px;
  font-family: 'Roboto', sans-serif !important;
  background-color: #fff !important;
}

.Toastify__toast-container--top-right {
  z-index: 99999 !important;
}

.btn-sm {
  padding: 5px 12px !important;
  font-size: 14px !important;
}

.btn-vsm {
  padding: 3px 11px !important;
  font-size: 10px !important;
}

.newdrop {
  width: 258px;
}

img.reviewsimg {
  width: 45px;
  height: 45px;
  border-radius: 31px;
}

h3.personname {
  font-size: 15px;
  font-weight: 600;
}

h3.dayscl {
  font-size: 15px;
}

button.replybtn {
  border: 1px solid #000000;
  border-radius: 30px;
  padding: 7px 12px;
  background: #fff;
}

.replybtn:before {
  content: "";
  display: block;
  width: 624px;
  height: 1px;
  background: #000;
  left: 88px;
  top: 90%;
  position: absolute;
}

.totale_review {
  font-size: 20px;
  font-weight: 600;
}

.holidays {
  display: block;
  font-size: 14px;
  margin-bottom: 9px;
  position: relative;
  padding-left: 15px;
  cursor: pointer;

  input {
    margin-right: 10px;
  }

  &.active {
    color: $primaryColor;
  }

  >.bold {
    font-weight: 500;
  }

  // &::before{
  //     content: "";
  //     height: 5px;
  //     width: 5px;
  //     position: absolute;
  //     background-color: #000;
  //     left: 0;
  //     top:8px;
  //     border-radius: 50%;
  // }
}

a:disabled,
.disabled {
  pointer-events: none;
}

.paginationWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;

  >span {
    font-size: 13px;
    color: #84818A;
  }
}

.chip {
  background: #7561a2;
}

.alignTopDate div.ant-picker-input {
  top: -12px;
}

.react-pagination-js-default {
  ul {
    display: flex;
    align-items: center;
    column-gap: 10px;

    >li {
      margin: 0 !important;
      font-size: 13px !important;
      padding: 7px 13px !important;

      &:first-child {
        padding: 0 !important;

        &:not(.disabled) {
          color: #1F7BF4 !important;
        }

        &::after {
          content: "< Previous";
        }

        a {
          display: none;
        }
      }

      &:last-child {
        padding: 0 !important;

        &:not(.disabled) {
          color: #1F7BF4 !important;
        }

        &::after {
          content: "Next >";
        }

        a {
          display: none;
        }
      }

      &.page {
        border-color: #0000 !important;
        background-color: transparent !important;
        box-shadow: none !important;
        font-weight: 400 !important;
      }

      &.is-active {
        font-weight: 500 !important;
        background-color: #fff !important;
        border-color: #1F7BF4 !important;
        box-shadow: none !important;
        text-shadow: none !important;
        color: #202020 !important;
      }
    }

  }

}

// body {
//   font-family: $font1  !important;

// }

a {
  color: #2f29a4;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font2;
}

label {
  font-size: 14px;
  font-weight: 500;
  color: #202020;
}

.col_side div span {
  font-size: 14px;
  font-weight: 300;
  color: #4A545E;
  margin-right: -12px;
}

i.fa {
  cursor: pointer;
}

.dateRangePicker {
  padding: 10px !important;
  width: 100% !important;
}

.react-tooltip {
  max-width: 280px;
  word-break: break-all;
  white-space: initial;
  background: #fff !important;
  color: #000 !important;
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
}

.optionContainer {
  border: none !important;
  margin-bottom: 4px;
}

.min-sidebar~div>.main-sidebar a.col_side:hover {
  justify-content: center;
  margin-right: 0px !important;
}

.multiSelectContainer li:hover {
  background-color: #D0D5DD !important;
  color: #765aa6 !important;
  border-radius: 8px;
}

.optionListContainer {
  background: #fff;
  margin-top: 1px;
  position: absolute;
  width: 100%;
  z-index: 2;
  border: none;
  border-radius: 14px;
  padding: 0.7rem 0.5rem;
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
}

span.text-uppercase.mr-2.h6.currency-format.pointer:hover {
  text-decoration: underline;
}


.star {
  // color: red;
  display: none;
  color: #202020;
}

.action_icons {
  display: flex;
}

p.sign_up.ml-2.pointer.signup_color {
  color: #2f29a4;
}

a.edit_icon {
  /* border: 1px solid #DCDCDC; */
  height: 20px;
  width: 20px;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 5px;
  margin-right: 6px;

  &:hover {
    text-decoration: none !important;
  }
}

.mainarea {
  background: #f9f8fb !important;
  padding: 20px;
}

span.edit_icon {
  border: 1px solid #DCDCDC;
  height: 30px;
  width: 30px;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 5px;
  margin-left: 3px;
  cursor: pointer;
}

i.material-icons.edit {
  font-size: 15px;
  /* font-weight: 600; */
  color: #705EE6;
}

i.material-icons.delete {
  font-size: 15px;
  font-weight: 600;
  color: #898989;

}

// i.fa.fa-arrow-left {
//   color: #000;
// }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #F9F9F9 !important;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-bottom: 1px solid #fff !important;
  // text-transform: capitalize;
  // white-space: nowrap;
}



.inputWrapper {
  position: relative;

  .fa {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #84818A;
  }

  .sufix {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #84818A;
  }
}

.modal-title,
.dropdown-item {
  text-transform: capitalize;
}

input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #84818A !important;
  opacity: 1 !important;
  /* Firefox */
}

input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #000 !important;
  opacity: 1 !important;
}

.loaderDiv {
  z-index: 99999;
  backdrop-filter: blur(2px);
  align-items: center;
  justify-content: center;
  display: flex;
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}

.pageLoader {
  max-width: 50px;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #A0A4A8;
  opacity: 1;
  font-size: 14px;
  font-weight: 400;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #A0A4A8;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #A0A4A8;
}

main.main {
  background-color: #F6F6F6 !important;
}

.react-datepicker-popper {
  z-index: 99999;
}

// Loader start
img.loaderlogo {
  max-width: 50px;
  // animation: rotation 3s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

// Loader end

.btn {
  text-transform: capitalize;
  font-weight: 500;
  padding: 8px 25px;
  font-size: 16px;
  border-radius: 8px;
}


.btn-outline-primary {
  color: #D0D5DD;
  border-color: #D0D5DD;
}

a {
  cursor: pointer;
}

.taableWrapper {
  max-height: calc(100vh - 250px);
}

button.btn.btn-primary.loginclass {
  background: #2f29a4;
  border-radius: 5px;
  width: 100%;
  height: 49px;
  font-size: 14px;
  font-weight: 600;
  padding: 5px 15px;
}

@media(max-width:768px) {
  .taableWrapper {
    max-height: calc(100vh - 300px);
  }
}

.login-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;

  >.container {
    max-width: 500px;

    .shadow {
      border-top: 3px solid $primaryColor;
    }

    .login-logo {
      width: 100%;
      max-width: 150px;
    }
  }
}

.bginput {
  border: 1px solid #E8E8E8 !important;
  // background: #F6F6F6 !important;
  border-radius: 8px !important;
}

.empty-box {
  padding: 15px;
  text-align: center;

  .icon-box {
    padding: 15px 30px;
    font-size: 26px;
    margin-bottom: 10px;
  }
}

h3.text-left.lgtext {
  font-weight: 600;
  font-size: 24px;
}

.paraclss {
  font-weight: 400;
  font-size: 20px;
  line-height: 29px;
  color: #2f29a4 !important;
}


::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #F6F6F6;
  opacity: 1;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #F6F6F6;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #F6F6F6;
}


.modal {
  overflow-y: auto;

  .card-body,
  .modal-boldy {
    max-height: calc(100vh - 200px);
    overflow-y: auto;
  }
}

.upload-btn {
  position: relative;

  >input {
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
}

.pagination {

  >li {
    padding: 2px;

    a {
      padding: 7px 10px;
      display: inline-block;
      border: 1px solid #E8E8E8;
      border-radius: 4px;
      font-size: 14px;
      min-width: 40px;
      text-align: center;
      color: #000;
      text-decoration: none;

    }

    &.active {
      a {
        background-color: $primaryColor;
        border-color: $primaryColor;
        color: #fff;
      }

    }

    &.disabled {
      a {
        border-color: #E8E8E8;
        color: #E8E8E8;
      }
    }
  }

}


.shine {
  background: #eee;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;
  min-height: 20px;
  border-radius: 5px;
}


.shine.shineCard {
  height: 250px;
}

strong.shine {
  display: block;
  margin-bottom: 14px;
}


@keyframes shine {
  to {
    background-position-x: -200%;
  }
}



.table td,
.table th {
  padding: 0px 14px !important;
  vertical-align: middle;
  height: 40px;
}

// edit icon css
i.fa.fa-pencil-alt.edit {
  margin: 0px 15px;
  font-size: 18px;
  color: #7561a2;
  font-weight: 700;
}

i.fa.fa-trash {
  color: #c02e2e;
}

.filterFlex {
  white-space: nowrap;
  column-gap: 10px;
  row-gap: 10px;
  flex-wrap: wrap;
  display: flex;
  margin-bottom: 1rem;
  justify-content: end;
}


.checkPrice {
  column-gap: 10px;

  span {
    display: inline-block;

    input {
      margin-right: 5px;
    }
  }
}

// Responsive Design 
.buttons_Section {
  display: flex;
  align-items: center;
  margin-right: 12px;
}

.cardList {
  margin-top: 2rem;
}

a.btn.btn-primary.mr-2 {
  white-space: nowrap;
}

.form-control {
  padding: 21px 10px;
  border: 1px solid #DCDCDC;
  border-radius: 8px;

  &.invalid {
    border-color: red !important;
  }
}

select.form-control.types {
  height: 43px;
  margin-right: 12px;
  width: 100%;
  max-width: 200px;
}

p.phone_no {
  color: #84818A;
}

.new_cards {
  background: #fff;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  max-height: 300px;
  // margin-bottom: 4.2rem;
}

input.searchbar.mr-2 {
  padding: 8px;
  border-color: #cdd4da;
  border-radius: 5px;
  outline: none;
  border: 1px solid #cdd4da;
  height: 43px;
}

.d-flex.justify-content-between.align-items-center {
  flex-wrap: wrap;
}

// profile changes
input.searchbar.mr-2:focus {
  box-shadow: 0 3px 10px 0 17%;
  border-color: #7561a2;
}

a.btn.btn-primary.profiles {
  background-color: #fff !important;
  border: 1px solid #c2c2c2 !important;
  height: auto;
  color: #2020209c !important;
  border-radius: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;

}

i.material-icons.prob {
  font-size: 27px;
  margin: 0px 7px;
  color: #0000004a;
}

label.btn.btn-primary.delete.ml-3 {
  background-color: #fff !important;
  border-radius: 10px;
  color: #2f29a4 !important;
  font-size: 14px !important;
  font-weight: 500;
}

label.btn.btn-primary.edit.ml-3 {
  border-radius: 10px;
  font-weight: 600;
  font-size: 14px;
}

.btn.btn-secondary.discard {
  background-color: #fff !important;
  border: 1px solid #DCDCDC !important;
  color: #202020 !important;
  font-size: 14px !important;
  height: 42px;
  border-radius: 10px;
  font-weight: 500;

}

a.discard {
  text-decoration: none;
  color: #202020 !important;
  font-size: 14px;
  font-weight: 500;

  & :hover {
    color: #202020 !important;
    font-size: 14px;
    font-weight: 500;
  }
}

.btn.btn-primary.reset {
  background-color: #fff !important;
  border: 1px solid #DCDCDC !important;
  color: #202020 !important;
  font-size: 14px !important;
  height: 43px;
  border-radius: 10px;
}

button.btn.btn-primary.edit.ml-3 {
  border-radius: 10px;
  font-weight: 600;
  font-size: 14px;
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
  --bs-table-color-type: #202020 !important;
  --bs-table-bg-type: rgb(249 249 249 / 100%) !important;
}

a.btn.barlink.text-primary.active {
  outline: none;
  border: none;
}

a.btn.barlink.text-primary:active {
  border: none;
}

p.profile_data {
  margin-bottom: 0px !important;
  font-size: 14px;
  font-weight: 400;
  color: #5A5A5A;
}

.col-md-12.inputFlex {
  line-height: 30px;
}

.highlightOption:not(:hover) {
  background: initial;
  color: initial;
}

.currencyChart {
  position: relative;

  .textOver {
    position: absolute;
    text-align: center;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
  }
}


// Responsive css:

@media only screen and (min-width:1200px) and (max-width:1400px) {

  .sales_section {
    padding: 20px;
  }

  .graph_section {
    padding: 20px;
  }

  .min-sidebar~div>.main-sidebar i {
    margin-right: 0px !important;
  }
}

@media only screen and (min-width:1106px) and (max-width:1199px) {

  .sales_section {
    padding: 20px;
  }

  .graph_section {
    padding: 20px;
  }

  .min-sidebar~div>.main-sidebar i {
    margin-right: 0px !important;
  }

  .shadow.bg-white.p-3.text-center.roundedBorder.repeat {
    margin-top: 15px;
  }
}

@media screen and (min-width:1001px) and (max-width:1105px) {
  .min-sidebar~div>.main-sidebar i {
    margin-right: 0px !important;
  }

  .dropdown.addDropdown.reseller_status {
    margin-top: 20px;
  }

  .shadow.bg-white.p-3.text-center.roundedBorder.repeat {
    margin-top: 15px;
  }
}

@media only screen and (min-width:991px) and (max-width:1000px) {
  .shadow.bg-white.p-3.text-center.roundedBorder.repeat {
    margin-top: 15px;
  }

  .min-sidebar~div>.main-sidebar {
    display: none !important;

  }

  .main-navbar.min-sidebar {
    padding-left: 60px;
    display: flex;
  }

  .main-sidebar {
    position: absolute !important;
    top: 143px;
    z-index: 998;
  }

  .main-navbar {
    padding-left: 25px;
  }

  .sidebar-brand.p-3.pt-4.text-left.pl-5 {
    display: none;
  }

  .min-sidebar~div>main.main {
    width: 100% !important;
  }

  .d-flex.justify-content-between.align-items-end.mb-0 {
    display: block !important;
  }

  article.d-flex {
    margin-top: 1rem;
  }

  .main-wrapper main.main {
    width: 100% !important;
  }

  .d-flex.justify-content-between.align-items-end.mb-3 {
    display: block !important;
  }

  .selectDropdown::placeholder {
    width: 100px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  span {
    font-size: 14px;
  }

  span.side_head {
    font-size: 14px;
  }


  .main-navbar {
    padding-left: 60px !important;
  }
}








// responsive css//

// @media(min-width:768px){
//   .main-navbar.min-sidebar ~ div > .main-sidebar{
//     display: block !important;
//   }
// }


@media screen and (min-width:768px) and (max-width:1000px) {
  .main-navbar.min-sidebar~div>.main-sidebar {
    display: none !important;
  }

  .shadow.bg-white.p-3.text-center.roundedBorder.repeat {
    margin-top: 15px;
  }
}

@media screen and (max-width:767px) {

  .col-md-9.head_cls {
    margin-bottom: 20px;
  }

  .shadow.bg-white.p-3.text-center.roundedBorder.repeat {
    margin-top: 15px;
  }
}

@media only screen and (min-width:602px) and (max-width:990px) {
  .shadow.bg-white.p-3.text-center.roundedBorder.repeat {
    margin-top: 15px;
  }

  .main-navbar {
    padding-left: 60px !important;
  }

  .min-sidebar~div>.main-sidebar {
    display: none !important;

  }

  .main-navbar.min-sidebar {
    // padding-left: 60px;
    display: flex;
  }

  .main-sidebar {
    position: absolute !important;
    top: 144px;
    z-index: 998;
    max-height: 770px;
    height: 100%;
    overflow: auto;
  }

  .main-navbar {
    padding-left: 60px;
  }

  .sidebar-brand.p-3.pt-4.text-left.pl-5 {
    display: none;
  }

  .min-sidebar~div>main.main {
    width: 100% !important;
  }

  .d-flex.justify-content-between.align-items-end.mb-0 {
    display: block !important;
  }

  article.d-flex {
    margin-top: 1rem;
  }

  .main-wrapper main.main {
    width: 100% !important;
  }

  .d-flex.justify-content-between.align-items-end.mb-3 {
    display: block !important;
  }

  .selectDropdown::placeholder {
    width: 100px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .selectDD {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .col-12.col-sm-12.col-md-12.col-lg-6.cls {
    margin-top: 15px;
  }

  span {
    font-size: 14px;
  }

  span.side_head {
    font-size: 14px !important;
  }

  .card.adds {
    margin-top: 20px;
  }

}

@media screen and (min-width:691px) and (max-width:854px) {
  .shadow.bg-white.p-3.text-center.roundedBorder.repeat {
    margin-top: 15px;
  }

  .dropdown.addDropdown.reseller_name {
    margin-top: 0px !important;
  }

  .dropdown.addDropdown.reseller_status {
    margin-top: 20px;
  }
}

@media screen and (max-width:690px) {
  .dropdown.addDropdown.reseller_name {
    margin-top: 20px !important;
  }

  .dropdown.addDropdown.reseller_status {
    margin-top: 20px;
  }
}

@media only screen and (min-width:528px) and (max-width:600px) {
  .shadow.bg-white.p-3.text-center.roundedBorder.repeat {
    margin-top: 15px;
  }

  .main-navbar {
    padding-left: 60px !important;
  }

  .min-sidebar~div>.main-sidebar {
    display: none !important;

  }

  .main-navbar.min-sidebar {
    padding-left: 60px;
    display: flex;
  }


  .main-navbar {
    padding-left: 60px !important;
  }

  .sidebar-brand.p-3.pt-4.text-left.pl-5 {
    display: none;
  }

  .min-sidebar~div>main.main {
    width: 100% !important;
  }

  // proffile dropdown
  // .dropdown.ml-auto {
  //   top: -40px;
  // }

  // .headerSearch {
  //   top: 10px !important;
  // }

  // category css
  .d-flex.justify-content-between.align-items-end.mb-0 {
    display: block !important;
  }

  article.d-flex {
    margin-top: 1rem;
  }

  .d-flex.justify-content-between.mb-3 {
    display: block !important;
  }


  p.mb-0 {
    font-size: 13px;
  }

  img.listIc {
    height: 40px;
    width: 40px;
  }

  form.headerSearch.ml-3 {
    width: auto;
    max-width: 130px;
  }

  // .ml-3 {
  //   width: 300px;
  // }
  span {
    font-size: 14px;
  }

  span.side_head {
    font-size: 14px;
  }

  article.d-flex {
    margin-top: 1rem;
    flex-wrap: wrap;
  }

  button#dropdownMenuButtonstatusDropdown\ reset {
    margin-top: 15px;
  }

  // new css 19-07-2023//
  .dropdown.addDropdown.reseller_name {
    margin-top: 20px !important;
  }

  .dropdown.addDropdown.reseller_status {
    margin-top: 20px;
  }

}

@media screen and (max-width:590px) {
  button.btn.btn-primary.btnreset {
    margin-top: 15px !important;
  }

  a.btn.btn-primary.btnreset {
    margin-top: 15px !important;
  }

  .shadow.bg-white.p-3.text-center.roundedBorder.repeat {
    margin-top: 15px;
  }
}

@media only screen and (min-width:320px) and (max-width:527px) {
  .shadow.bg-white.p-3.text-center.roundedBorder.repeat {
    margin-top: 15px;
  }

  .login_img {
    top: 9px !important;
  }

  .right_side {
    padding: 1rem 15px !important;
  }

  .main-navbar {
    padding-left: 60px !important;
  }

  .min-sidebar~div>.main-sidebar {
    display: none !important;

  }

  form.pprofile1.form-row.py-3.w-50.mx-auto {
    width: 100% !important;
  }

  .text-center.d-flex.justify-content-between.mt-5 {
    display: flex !important;
  }

  form.centerLogin div {
    margin-bottom: 22px !important;
    margin-top: 15px !important;
  }

  p.accopunt {
    text-align: right;
    right: 20px;
    // padding: 0rem 0rem !important;
    font-size: 14px;
    font-weight: 400;
    top: 8px;
  }

  p.accopunts {
    position: relative !important;
  }

  .google_id {
    width: 48%;
  }

  a.btn.barlink.text-primary {
    left: 15px;
    width: 32px;
    height: 25px;
    padding: 0px 0px;
    top: 25px;
  }

  .main-sidebar {
    position: absolute !important;
    top: 143px;
    z-index: 998;
  }

  .main-navbar.min-sidebar {
    padding-left: 40px;
    display: flex;
  }

  .main-navbar {
    padding-left: 40px !important;
  }

  .headerSearch .Searchbar {
    width: 100%;
    max-width: 130px;
  }

  .sidebar-brand.p-3.pt-4.text-left.pl-5 {
    display: none !important;
  }

  .min-sidebar~div>main.main {
    width: 100% !important;
  }

  // proffile dropdown
  // .dropdown.ml-auto {
  //   top: -30px;
  //   right: -16px;
  // }

  .headerSearch {
    top: 20px !important;
  }



  // main p-age


  .d-flex.justify-content-between.align-items-end.mb-3 {
    display: block !important;
  }

  .nav-tabs {
    border-radius: 3px !important;
    height: 40px !important;
    background: none !important;
    border: none !important;
    margin-top: 1rem;
  }

  .icons_tab {
    margin: 0px !important;
  }

  .nav-tabs {
    border-bottom: 1px solid #ffffff;
  }

  article.d-flex {
    display: block !important;
    margin-top: 1rem !important;
  }

  .new_cards {
    height: 100% !important;
    margin-bottom: 0rem !important;
    max-height: 500px !important;
    //  height: 100%;
    //   margin-bottom: 0rem !important;
  }

  li.list_names {
    text-align: left !important;
  }

  .user_proff {
    display: block !important;
  }

  // category css
  .d-flex.justify-content-between.align-items-end.mb-0 {
    display: block !important;
  }

  select.form-control.types {
    margin-top: 13px;
    width: 100%;
  }

  // .d-flex.justify-content-between.mb-3 {
  //   display: block !important;
  // }

  // .d-flex.justify-content-between {
  //   display: block !important;
  // }

  button.btn.btn-primary.btnreset {
    margin-top: 15px;
  }


  // dashboard

  h2.mb-1.mainHeading_cls {
    font-size: 20px;
    font-weight: 600;
  }

  p.grayCls.mb-0 {
    font-size: 12px;
    font-weight: 200;
    color: #898989;
    width: auto !important;
  }

  a.btn.barlink.text-primary {
    left: 15px;
    width: 32px;
    height: 25px;
    padding: 0px 0px;
    top: 25px;
  }

  form.headerSearch.ml-3 {
    width: 100% !important;
    margin-left: 9px !important;
  }

  p.mb-0 {
    white-space: nowrap;
    width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  // .ml-3 {
  //   width: 200px;
  // }
  img.listIc {
    height: 40px;
    width: 40px;
  }

  .borderBox.d-flex.align-items-center.justify-content-between {
    padding: 0px 15px;

  }

  .timeBoxs p {
    font-size: 14px;
    color: #484848;
    line-height: 17px;
  }

  .headerSearch .Searchbar {
    width: 100%;
    max-width: 130px;
  }

  // form.headerSearch.ml-3 {
  //   width: 130px;
  // }

  // dashboard responsive
  .graph_range {
    display: block;
  }

  p.view_graph {
    margin-top: 18px;
  }


  // admin user
  .d-flex.justify-content-between.align-items-center {
    display: block !important;
  }

  span {
    font-size: 14px;
  }

  span.side_head {
    font-size: 14px;
  }

  .dropdown.addDropdown {
    margin-top: 15px;
  }

  // new css 19-07-2023
  .hedding {
    margin-bottom: 15px !important;
  }

  h2.mb-5 {
    font-size: 18px !important;
    margin-bottom: 20px !important;
  }

  .dropdown.addDropdown.booking_system.mr-2.w-100 {
    margin-top: 0px !important;
  }
}

@media screen and (min-width:320px) and (max-width:767px) {
  .hedding {
    margin-bottom: 20px !important;
  }

  .text-center.tableHeading h2 {
    font-size: 23px !important;
  }

  .sidebar-brand.p-3.pt-4.text-left.pl-5 {
    display: none !important;
  }

  .min-sidebar~div>.main-sidebar a.col_side:hover {
    justify-content: center;
    margin: 8px 14px !important;
  }

  .min-sidebar~div>.main-sidebar {
    display: block !important;
    max-width: 250px;
    min-width: 250px !important;
    // min-height: 100vh;
    height: 100%;
    margin-top: -72px;
    border-right: 1px solid #E8E8E8;
    position: absolute;
    z-index: 1;
    top: 145px;
    max-height: 770px;
    overflow: auto;
  }

  .sidees {
    display: flex;
  }

  .min-sidebar~div>.main-sidebar a.nav-link.hoverclass {
    padding: 9px 16px;
    border-radius: 12px !important;
    margin-right: 10px !important;
    display: flex !important;
    justify-content: left !important;
    width: auto !important;
  }

  .min-sidebar~div>.main-sidebar a.col_side {
    padding: 9px 16px;
    border-radius: 12px !important;
    margin-right: 10px !important;
    display: flex !important;
    justify-content: space-between !important;
    width: auto !important;
  }

  .main-navbar {
    padding-left: 50px !important;
  }

  .btn.btn-secondary.discard {
    background-color: #fff !important;
    border: 1px solid #DCDCDC !important;
    color: #202020 !important;
    font-size: 14px !important;
    height: 43px;
    border-radius: 8px;
    font-weight: 500;

  }

  .min-sidebar~div>.main-sidebar .nav-link span {
    display: block !important;
  }

  .min-sidebar~div>.main-sidebar a.col_side span {
    display: flex !important;
    font-size: 14px !important;
    font-weight: 300 !important;
    color: #202020;
  }

  .min-sidebar~div>.main-sidebar i {
    margin-right: 8px !important;
  }

  .main-sidebar {
    display: none !important;
  }

  .main-sidebar .nav .nav-item .nav-link i.sidenv {
    display: none !important;
  }

  .card.card-body.sides li a {
    border-radius: 4px !important;
    padding-left: 42px !important;
  }
}


@media only screen and (min-width:320px) and (max-width:500px) {
  .main-navbar {
    padding-left: 25px !important;
  }

  a.btn.barlink.text-primary {
    left: 15px;
    width: 32px;
    height: 25px;
    padding: 0px 0px;
    top: 25px;
  }

  button:focus:not(:focus-visible) {
    outline: 0;
    box-shadow: none !important;
  }
}


/////////////############ Sales ############////////////
.textOver h3 {
  font-size: 10px;
}

.new_div {
  display: flex;
  justify-content: end;
}

.download_icon {
  border-radius: 8px;
  background: #F1F0FC !important;
  color: #6956E5 !important;
  border: 1px solid #D0D5DD !important;
  padding: 8px 15px;
}

.download_icon i {
  color: #6956E5 !important;
}

.download_icon2 {
  border-radius: 8px;
  background: #e9fbf6 !important;
  color: #047857 !important;
  border: 1px solid #D0D5DD !important;
  padding: 8px 15px;
}

.download_icon2 i {
  color: #047857 !important;
}

.download_icon2:hover {
  background: #e9fbf6 !important;
  border: 1px solid #D0D5DD !important;
}

.btn_color {
  background: #fff;
  border: 1px solid #d7dbe2;
  border-radius: 8px !important;
  margin-right: 1px;
  color: #111827;
  font-weight: 500;
}

.btn_color:hover {
  background: #fff;
}

.main-btn {
  background: #f3f3f3;
}


.labelbtn {
  background: #ffffff00;
  color: #6B7280;
}

.labelbtn:hover {
  background: #ffffff00 !important;
  border: 1px solid #f8f9fa;
}

.btn_btn {
  display: inline-flex;
  justify-content: center;
  color: #344054;
}

input.inputdesign {
  border: 1px solid #D0D5DD;
  border-radius: 6px;
  background: #fff;
  padding: 8px 14px;
}

.parimarybtn span {
  color: #7E858E;
  font-size: 16px;
  position: relative;
  top: 2px;
}

.sales_layout {
  display: flex;
  justify-content: space-between;
  border: 1px solid #EAECF0;
  border-radius: 8px;
  padding: 10px 20px;
  background-color: #fff;
  align-items: center;
}

.list_box {
  display: flex;
  align-items: center;
}

.list span {
  color: #6956E5;
  background: #F4F5F6;
  width: 48px;
  height: 48px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 38px;
}

.list_box h1 {
  font-size: 16px;
  margin-bottom: 5px;
}

.list_box {
  display: flex;
  align-items: center;
}

.list {
  margin: 0px 10px;
  margin-right: 20px;
  position: relative;
}

.list_boxRight h2 {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0px;
}

.list_box p {
  font-size: 14px;
  color: #484848;
  margin-bottom: 0px;
}

.list_boxRight span {
  font-size: 14px;
  color: #fff;
  border-radius: 2px;
}

.red_icon {
  background: #e9253133;
  color: #E92531;
  padding: 5px 5px;
  border-radius: 30px;
  font-size: 12px;
  margin-right: 5px;
}

.green_icon {
  background: #04785714;
  color: #047857 !important;
  padding: 5px 5px;
  border-radius: 30px;
  font-size: 12px;
  margin-right: 5px;
}

.red_text {
  color: #FC0000 !important;
}

.list_boxRight p {
  font-size: 14px;
  color: #202020;
}

p.msg_list {
  font-size: 9px;
  background: #FFEA8A;
  width: fit-content;
  padding: 0px 5px;
  border-radius: 50px;
  position: absolute;
  margin-bottom: 0px;
  top: -5px;
  right: 8px;
  border: 1px solid #fff;
}

.layout {
  border: 1px solid #EAECF0;
  border-radius: 8px;
  width: 100%;
  padding: 15px;
  background-color: #fff;
}

h1.layout_heading {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 0px;
  color: #3C3E49;
  padding: 0px;
}

.right_order {
  border: 1px solid #EAECF0;
  border-radius: 8px;
  width: 100%;
  padding: 15px;
  background-color: #fff;
}

.right_layout {
  display: flex;
  justify-content: space-between;
}

.right_order h2 {
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 0px;
}

.right_order p {
  font-size: 14px;
  color: #696974;
  margin-bottom: 0px;
}

.right_order span {
  font-size: 12px;
}

.order {
  width: 60%;
  margin-left: auto;
}

.box_border {
  margin-top: 1rem;
  border-top: 1px solid #F1F1F5;
  padding-top: 1rem;
}

.box_border {
  margin-top: 1rem;
  border-top: 1px solid #F1F1F5;
  padding-top: 1rem;
}

.header_list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.parimarybtn {
  border-radius: 8px;
  background: #fff !important;
  color: #202020 !important;
  border: 1px solid #D0D5DD !important;
  padding: 8px 15px;
}

.parimarybtn2 {
  border-radius: 8px;
  background: #fff !important;
  color: #6F3BCE !important;
  // border: 1px solid #D0D5DD !important;
  border: none;
  padding: 8px 15px;
}

.parimarybtn span {
  font-size: 15px;
  margin-right: 4px;
}

.data_row:not(tr) {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 1.5rem;
  flex-wrap: wrap;
}

.list_row p {
  font-size: 12px;
  color: #667085;
  margin-bottom: 4px;
}

.list_row h1 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
}

// .list_row {
//   width: 19%;

// }

.progress_list {
  display: flex;
  align-items: center;
}

.progress_row {
  width: 100%;
  margin: 0px 5px;
  background: none;
  border-radius: 0px;
  height: 24px;
  position: relative;
}

.line_row {
  display: flex;
  align-items: center;
}

.line_row2 {
  display: flex;
  align-items: center;
  width: -webkit-fill-available;
}

.line_row p {
  margin-bottom: 0px;
  color: #484848;
  font-size: 16px;
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.line_row2 p {
  margin-bottom: 0px;
  color: #484848;
  font-size: 12px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.progress_list {
  display: flex;
  align-items: center;
  padding-top: 1.5rem;
}

.progress-bar.progress_list {
  background: #B2B3B4;
  border-radius: 4px;
  position: relative;
}

.footer_chart a {
  font-size: 16px;
  color: #6956E5;
  text-align: center;
  display: flex;
  align-items: center;
  margin: auto;
  justify-content: center;
  margin-bottom: 0px;
  border-top: 1px solid #EAECF0;
  margin-top: 1rem;
  padding-top: 1rem;
}

.footer_chart a:hover {
  color: $primaryColor !important;
  text-decoration: none;
}

.footer_chart span {
  margin-left: 5px;
  font-size: 14px;
}

.parimarybtn span {
  color: #898989;
  font-size: 16px;
  position: relative;
  top: 2px;
}

.btn_2 .btn {
  padding: 10px 15px !important;
  border-radius: 6px;
  background: #fff;
  color: #344054 !important;
  border: 1px solid #d0d5dd;
}

label.btn:hover {
  color: #344054;
}

.Countr_text h1 {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 0px;
  color: #3C3E49;
  margin-right: 10px;
}

.Countr_text p {
  font-size: 14px;
  color: #696974;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
}

.left_design {
  display: flex;
  justify-content: space-between;

  p {
    margin-bottom: 0;
  }

  .text-truncate {
    width: 110px;
  }
}

.Countr_text {
  margin-top: 1rem;
}

.Countr_text p {
  font-size: 14px;
  color: #6B7280;
  margin-bottom: 0px;
}

.design {
  margin-top: 2rem;
}

.left_design {
  margin-bottom: 1rem;
}

.left_design p {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 600;
}

.green_text {
  background: none;
}

.red_text {
  background: none;
}

p.bold_text {
  color: #6B7280 !important;
  font-weight: 600 !important;
}

.border_line {
  padding-right: 10px;
}

.box_round {
  width: 10px;
  height: 10px;
  background: $primaryColor;
  border-radius: 10px;
  margin-right: 10px;
}

.box_round2 {
  width: 10px;
  height: 10px;
  background: #FF974A;
  border-radius: 10px;
  margin-right: 10px;
}

.box_round3 {
  width: 10px;
  height: 10px;
  background: #FFC542;
  border-radius: 10px;
  margin-right: 10px;
}

.box_round4 {
  width: 10px;
  height: 10px;
  background: #cacaca;
  border-radius: 10px;
  margin-right: 10px;
}

.box_round5 {
  width: 10px;
  height: 10px;
  background: #e7e5e5;
  border-radius: 10px;
  margin-right: 10px;
}

span.progress_value {
  color: #3C3E49;
  font-size: 14px;
  text-align: right;
  margin-left: 5px;
  position: absolute;
  top: 2px;
  left: 5px;
}

span.progress_value2 {
  color: #3C3E49;
  font-size: 14px;
  text-align: right;
  margin-left: 5px;
  position: absolute;
  top: 2px;
  right: 5px;
}

.border_line {
  border: 1px solid #f1f1f1;
  height: 26px;
  border-radius: 6px;
}

.line_row img {
  width: 32px;
  height: 32px;
  margin-right: 5px;
  border-radius: 50%;
}

.line_row2 img {
  width: 32px;
  height: 32px;
  margin-right: 5px;
  border-radius: 4px;
}

.switch_btn {
  min-height: unset;
  margin-left: 20px;
}

.switch_check {
  width: 36px !important;
  height: 20px;
  top: -4px;
}

.flag_div img {
  width: 28px;
  height: 20px;
  margin-left: 5px;
}

.flag_div {
  display: flex;
  align-items: center;
}

tr.data_row {
  height: 50px;
}


/////////////############ Marketing ############////////////
.filterFlex2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

h1.text_heading {
  font-size: 24px;
}

.sales_flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Sales_box h2 {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 0px;
}

.sales_flex p {
  margin-bottom: 0;
  color: #484848;
}

.slaes_flex p {
  color: #667085;
  margin-bottom: 0px;
}

// .sales_flex span {
//   font-size: 14px;
//   color: #fff;
//   border-radius: 2px;
//   margin-left: 5px;
// }
.Sales_box {
  border: 1px solid #eaecf0;
  border-radius: 12px;
  padding: 15px;
}

.Sales_row {
  // border: 1px solid #eaecf0;
  border-radius: 12px;
  padding: 15px;
  background: #fff;
}

.layout_box {
  display: flex;
  justify-content: space-between;
}

.Sales_box {
  width: 19%;
  background: #fff;
}

.btn_flex {
  display: flex;
}

span.icon_i {
  border: 1px solid;
  padding: 0px 5px;
  font-size: 13px;
  border-radius: 50%;
  color: #84818A;
  position: relative;
  top: -2px;
  margin-left: 4px;
}

.btn i {
  color: #484848;
}


/////////////############ Dashboard ############////////////
.btn_button {
  display: flex;
}

.download_icon:hover {
  background: #F1F0FC !important;
}

.btn_button h1 {
  margin-bottom: 0px;
  font-size: 16px;
  height: 42px;
  padding: 12px 20px;
  color: #6B7280;
  cursor: pointer;
}

.btn_button {
  background: #a4acb914;
  height: 42px;
  border-radius: 8px;
}

.tab_btn2 {
  color: #111827 !important;
  background: #fff;
  border-radius: 8px;
  border: 1px solid #d0d5dd;
}

tr.data_row th {
  color: #6B7280;
}

tr.data_row td {
  color: #6B7280;
}

.header_list p {
  font-size: 12px;
  color: #6B7280;
}

.review_text {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.id_text {
  width: 80%;
}

.review_main {
  display: flex;
  align-items: center;
}

.icons_div p {
  // margin-left: 10px;
  margin-bottom: 0px;
  color: #6B7280;
  font-size: 12px;
}

.review_main h2 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 0px;
  color: #3C3E49;
}

.Sales_row p {
  font-size: 16px;
  color: #111827;
  margin-bottom: 10px;
}

.Sales_row h2 {
  font-size: 20px;
  font-weight: 600;
  /* overflow: hidden; */
  /* text-overflow: ellipsis; */
  /* -webkit-line-clamp: 1; */
  /* -webkit-box-orient: vertical; */
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.className_box {
  display: flex;
  justify-content: space-between;
}

span.text_color {
  font-size: 12px;
  color: #6B7280;
}

span.red_icon .material-icons {
  font-size: 17px;
  position: relative;
  top: 4px;
}

span.green_icon .material-icons {
  font-size: 17px;
  position: relative;
  top: 4px;
  color: #047857;
}

.table td {
  border: 1px solid #f3f3f3;
}

.table th {
  border: 1px solid #f3f3f3;
}

.dashboard_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  flex-wrap: wrap;
}

.icon_ibtn {
  color: #fff !important;
  font-size: 19px;
  position: relative;
  top: 2px;
}

span.drow_text {
  position: relative;
  top: 0;
  font-size: 16px;
  color: #4B5563;
}

.icon_ibtn2 {
  color: #6F3BCE !important;
  font-size: 19px;
  position: relative;
  top: 2px;
}

.dashboard_row h1 {
  margin-bottom: 0px;
  font-size: 24px;
  font-weight: 600;
  color: #3C3E49;
}

.dashboard_row p {
  color: #98A2B3;
  font-size: 14px;
  margin-bottom: 0px;
}

.data h1 {
  margin-bottom: 0px;
  font-size: 24px;
  font-weight: 600;
  color: #3C3E49;
}

.border-0 {
  border: 1px solid #eaecf0 !important;
  width: fit-content;
  margin-top: 0px !important;
  padding: 4px 10px !important;
  font-size: 16px !important;
  border-radius: 8px;
  color: #4B5563 !important;
}

.data p {
  color: #3C3E49;
  font-size: 16px;
  margin-bottom: 1.5rem;
}

.data {
  border: 1px solid #F3F4F5;
  border-radius: 4px;
  padding: 20px;
}

.orders_layout {
  display: flex;
  justify-content: space-between;
  background: #FAF9FF;
  padding: 15px;
  border: 1px solid #eaecf0;
  border-radius: 8px;
}

// .value_orders {
//   width: 19%;
// }
.value_orders p {
  font-size: 12px;
  color: #3C3E49;
  margin-bottom: 10px;
}

.value_orders h1 {
  font-size: 18px;
  font-weight: 600;
  color: #3C3E49;
  margin-bottom: 0px;
}

span.span_text {
  font-weight: 100;
  margin-left: 10px;
}

img.wallets_img2 {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.review_layout {
  border-bottom: 1px solid #f2f3f5;
  padding-bottom: 1rem;
}

.review_layout {
  display: flex;
}

.review_text h1 {
  font-size: 14px;
  margin-bottom: 0px;
  display: flex;
  // align-items: center;
  margin-left: 10px;
  color: #3C3E49;
}

span.time_text {
  font-size: 12px;
  color: #7E858E;
  margin-left: 10px;
  margin-right: 10px;
  white-space: nowrap;
}

span.icon_span span {
  font-size: 20px;
  color: #FCBE72;
}

.review_text p {
  margin-left: 10px;
  margin-bottom: 0px;
  color: #7E858E;
  font-size: 12px;
}

img.wallets_img3 {
  width: 36px;
  height: 36px;
  margin-right: 5px;
  border-radius: 50%;
}

.table thead th {
  vertical-align: middle !important;
}

a.border_chart {
  border: 0px !important;
  padding-top: 0px !important;
  margin-top: 0px !important;
}

.parimarybtn:hover {
  background: #fff !important;
  color: #000 !important;
}

.parimarybtn:focus {
  box-shadow: none !important;
}


/////////////############ Scroll CSS ############////////////

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(180, 179, 179);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(139, 139, 139);
}

/////////////############ Scroll CSS End ############////////////
.sidees {
  display: flex;
  align-items: center;
}

p.text_p {
  margin-bottom: 0px;
  color: #75757A;
}

.inputOverlapIcon {
  z-index: 9999;
}

.googleChart text {
  font-size: 12px;
  color: #111827;
}

.profile_sales img {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background: #EFEFFC;
  margin-right: 1rem;
}

.sale_details {
  display: flex;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 15px;
}

.list_detail {
  display: flex;
  align-items: center;
}

.sale_details h1 {
  font-size: 16px;
  margin-bottom: 10px;
  color: #111827;
  font-weight: 600;
}

h3.Viewtext {
  font-size: 24px;
  font-weight: 600;
}

h4.company_heading {
  margin-bottom: 10px;
  font-size: 16px;
  color: #111827;
}

h4.company_name i {
  margin-right: 5px;
  color: #FCBE72 !important;
}

.light_btn {
  border: 1px solid #ced4da;
}

.flex_info {
  display: flex;
  justify-content: space-between;
}

.flex_info p {
  width: 200px;
  margin-right: 2rem;
  color: #6B7280;
}

.box_row {
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 8px;
}

.dflex_div {
  display: flex;
  justify-content: space-between;
  max-width: 300px;
  margin-left: auto;
}

.dflex_div p {
  margin-bottom: 10px;
  color: #6B7280;
  font-size: 16px;
}

p.dflex_text {
  color: #3C3E49;
}

p.dflex_text2 {
  color: #705EE6;
}

img.flag_img {
  width: 28px;
  height: 20px;
  margin-right: 10px;
}

img.items_img {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 10px;
}

p.back_text {
  color: #7E858E;
  width: fit-content;
  display: flex;
  align-items: center;
  cursor: pointer;
}

p.back_text i {
  color: #171F46;
}

.data_layout {
  height: 342px;
}

.chart_layout.mb-3 {
  height: 535px;
}

.right_value {
  padding: 1.2rem 0px;
}

.chart_2 {
  height: 347px;
}

.height_grafh {
  height: 342px;
}

.height_chart {
  height: 494px;
}

.footer_chart2 {
  padding-top: 1rem;
  border-top: 1px solid #f7f7f7;
  margin-top: 1rem;
}

.layout_hight {
  height: 250px;
  overflow: auto;
  padding: 0px 5px;
}

.list_text {
  display: flex;
  margin-top: 1.6rem;
}

.list_text span {
  margin-right: 15px;
}

.list_text span {
  margin-right: 15px;
  font-size: 20px;
  color: #0E9F6E;
  background: #F3FAF7;
  padding: 2px 2px;
  border-radius: 50%;
}

.list_text p {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.list_text2 span {
  color: #FCBE72;
  background: #fff6ea;
  padding: 2px 2px;
  border-radius: 50%;
}

span.start_row span {
  font-size: 20px;
  margin-left: 10px;
  position: relative;
  top: 3px;
}

.color_start {
  color: #FCBE72;
}

.removeBg {
  height: 37px;
}

.css-13cymwt-control {
  border-radius: 8px !important;
}

.detail_data .material-icons {
  font-size: 18px;
  color: #98A2B3 !important;
  margin-right: 5px;
  margin-left: 0px !important;
}

.detail_data span {
  margin: 0px 5px;
}

img.img_slider {
  height: 170px;
  object-fit: cover;
  border-radius: 4px;
}

ol.carousel-indicators li {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

ol.carousel-indicators {
  margin-bottom: 0px;
}

.description p {
  font-size: 12px;
  color: #3C3E49;
  line-height: 22px;
}

span.price_deta {
  font-size: 12px;
  color: #3C3E49;
}

p.general .material-icons {
  margin-right: 10px;
}

a.btn.btn-primary:hover {
  color: #fff !important;
}

.review_main2 {
  display: flex;
  align-items: center;
  background: #F8F9FB;
  padding: 15px;
  margin-bottom: 2rem;
}

.review_main2 h2 {
  font-size: 24px;
  font-weight: 600;
}

.heading_details .material-icons {
  color: #3C3E49;
  margin-right: 15px;
}

.icons_div h3 {
  font-size: 14px;
  font-weight: 600;
}

h1.location_heading {
  font-size: 14px;
  font-weight: 600;
  color: #3C3E49;
  padding: 0px 15px;
}

.location_layout {
  display: flex;
  padding: 0px 15px;
}

.location_layout .material-icons {
  color: #6956E5;
  font-size: 16px;
  width: 35px;
  height: 35px;
  background: #6956e514;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.location_layout {
  display: flex;
  padding: 12px 15px;
  border-top: 1px solid #EAECF0;
}

.location_layout p {
  font-size: 12px;
  color: #3C3E49;
  max-width: 77%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

img.money_bag {
  width: 24px;
  margin-right: 10px;
}

.border_heading {
  padding: 0px !important;
  border-bottom: 0px !important;
}

.green_color {
  color: #0AA630 !important;
}

.box_details {
  border: 1px solid #eaecf0;
  border-radius: 8px;
  border-left: 4px solid #B9EB8E;
}

.box_details2 {
  border-left: 4px solid #7A74EC;
}

.box_details3 {
  border-left: 4px solid #FCBE72;
}

.tab_icons .material-icons {
  font-size: 18px;
  border: 1px solid #EAECF0;
  padding: 4px 4px;
  border-radius: 4px;
  cursor: pointer;
}

.color1 {
  color: #6956E5;
}

.color2 {
  color: #6B7280;
}

tr.data_row h1 {
  font-size: 14px;
  color: #3C3E49;
  margin-bottom: 0px;
}

tr.data_row p {
  margin-bottom: 0px;
  color: #6B7280;
  font-size: 12px;
}

td.vertical {
  vertical-align: unset;
}

td.vertical {
  padding: 10px 10px !important;
}

button.btn_table {
  color: #0E9F6E;
  background: #04785714;
  border-radius: 4px;
  border: none;

  &.danger {
    color: red;
    background-color: #ffe2e2;
  }
}

span.spanBtn_1 {
  padding: 2px 15px;
  background: #0e9f6e24;
  color: #0E9F6E !important;
  border-radius: 30px;
  font-size: 12px;
  height: 22px;
  cursor: pointer;
}

span.spanBtn_2 {
  padding: 2px 15px;
  background: #00aaff24 !important;
  color: #00AAFF !important;
  border-radius: 30px;
  font-size: 12px;
  height: 22px;
  cursor: pointer;
}

span.spanBtn_3 {
  padding: 2px 15px;
  background: #feb77424 !important;
  color: #FEB774 !important;
  border-radius: 30px;
  font-size: 12px;
  height: 22px;
  cursor: pointer;
}

h4.heading_4 {
  color: #3C3E49;
  font-size: 12px;
  margin-bottom: 1rem;
}

table.table_price>tbody>tr>td:nth-of-type(1) {
  color: #705EE6
}

form.search_box .material-icons {
  position: absolute;
  top: 8px;
  left: 8px;
  color: #717275;
  font-size: 22px;
}

form.search_box {
  position: relative;
}

form.search_box input {
  padding-left: 28px;
}

.star-icon {
  color: #FCBE72;
}

.replied {
  padding: 4px 15px;
  background: #0e9f6e24;
  color: #0E9F6E !important;
  border-radius: 30px;
  font-size: 12px;
  height: 22px;
  cursor: pointer;
}

.not_replied {
  padding: 4px 15px;
  background: #feb77424 !important;
  color: #FD9210 !important;
  border-radius: 30px;
  font-size: 12px;
  height: 22px;
  cursor: pointer;
}

.company_discription {
  width: 100%;
}

a.btn.btn-primary.profiles:hover {
  color: #7a7a7a !important;
}

.m_modal {
  width: 100%;
}

.media {
  align-items: center;
}

.login_img {
  text-align: center;
}

img.logos {
  width: 200px;
  margin-bottom: 2rem;
}

.width_btn {
  border: 1px solid #e9e9e9;
  background: #EAECF0;
}

.company_info {
  display: flex;
  align-items: center;
}

.description_edit p {
  border: 1px solid #eaecf0;
  padding: 7px 15px;
  min-height: 37px;
  border-radius: 8px;
  width: 100%;
}

.text_logo h3 {
  font-size: 16px;
  margin-bottom: 1rem;
  margin-right: 3rem;
  color: #484848;
}

.sidenv {
  width: 38px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-navbar.min-sidebar {
  margin-left: 68px !important;
}

.dropdown-menu.show {
  display: block;
  z-index: 99999 !important;
}

.material-icons {
  font-size: 20px;
}

.data_layout {
  height: 342px;
  overflow: auto;
  padding: 0px 5px;
}



.block_div {
  display: none;
}

@media(max-width:991px) {
  .main-sidebar {
    margin-top: 0px !important;
  }

  .main-navbar.min-sidebar {
    margin-left: 0px !important;
  }

  .icon_ibtn {
    font-size: 15px;
  }

  .btn {
    font-size: 12px !important;
    padding: 8px 15px;
  }

  .filterFlex {
    justify-content: end;
  }

  .btn_button h1 {
    font-size: 12px;
    height: 40px;
  }

  .btn_button {
    height: 40px;
  }

  .Sales_row p {
    font-size: 14px;
  }

  .Sales_row h2 {
    font-size: 18px;
  }

  span.green_icon .material-icons {
    font-size: 14px;
  }

  .green_icon {
    font-size: 10px;
  }

  span.text_color {
    font-size: 10px;
    color: #6B7280;
  }
}

@media(max-width:767px) {
  .right_side {
    height: 100% !important;
  }

  p.accopunt {
    top: 25px !important;
  }

  .right_side {
    padding: 2rem 15px;
  }

  .login_img {
    top: 25px;
  }

  .main-navbar {
    background-color: #fff;
    color: #fff;
    height: 72px;
    margin-left: 0px !important;
  }

  .btn-flex {
    width: 100%;
    text-align: end;
    margin-top: 10px;
  }

  .dashboard_row {
    margin-bottom: 15px;
  }

  .value_orders h1 {
    font-size: 14px;
  }

  .value_orders p {
    margin-bottom: 2px;
  }
}

@media(max-width:575px) {
  .Sales_row p {
    width: unset;
  }

  .header_list {
    flex-wrap: wrap;
  }

  .btn_flex {
    margin-left: 10px;
  }

  .mainarea {
    padding: 10px 0px !important;
  }

  .orders_layout {
    justify-content: left;
    flex-wrap: wrap;
  }

  .value_orders {
    width: 29%;
    margin-bottom: 10px;
    margin-right: 10px;
  }
}

@media(max-width:499px) {
  .progress_row {
    margin-left: 2.3rem;
  }

  .progress_list {
    flex-wrap: wrap;
  }

  .review_text h1 {
    flex-wrap: wrap;
  }

  img.wallets_img2 {
    width: 30px;
    height: 30px;
  }

  h1.layout_heading {
    font-size: 16px;
  }

  .progress_list {
    padding-top: 0.5rem;
  }
}

table th {
  white-space: unset !important;
}

span.list_product {
  white-space: break-spaces;
  width: 90px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.css-13cymwt-control {
  min-width: 150px;
}

.add_content {
  display: flex;
  background: #fff !important;
  color: #2f29a4 !important;
}

.add_content:hover {
  display: flex;
  background: #fff !important;
  color: #2f29a4 !important;
}


//////////##### Marketing New Pgae css ///////////#####

.marketbox {
  background: #fff;
  border-radius: 8px;
  border: 1px solid #ececec;
  padding: 15px;
}

button.btn_online {
  background: #DDE9FF;
  border: 1px solid #b8d1ff;
  display: flex;
  align-items: center;
  border-radius: 8px;
  font-size: 16px;
  padding: 4px 10px;
  color: #1D2433;
}

button.btn_online span {
  font-size: 12px;
  color: #397CF6;
}

.box_textbox h1 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 0px;
}

.rowline {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rowline img {
  width: 50px;
  height: 27px;
}

.box_textbox {
  margin-top: 1.5rem;
}

.rowline p {
  font-size: 14px;
  color: #6B7280;
  margin-bottom: 0px;
}

span.red_text1 {
  color: #E0173C;
  margin-right: 10px;
}

.alrt_div {
  /* max-width: 700px; */
  margin: auto;
  background: #2f29a4;
  padding: 10px 15px;
  border-radius: 4px;
  margin-bottom: 1rem;
  margin-top: 1.5rem;
}

.text_div {
  color: #fff;
  margin-bottom: 1.5rem;
  text-align: left;
}

.text_div h1 {
  color: #141414;
  font-size: 18px;
  font-weight: 600;
  text-align: left;
}

.text_div li {
  color: #141414;
  list-style: disc;
  margin-left: 2rem;
}

.text_div p {
  color: #f5f5f5;
  margin-bottom: 0px;
}

.header-dark-page {
  background-color: #2e2da2;
}

.alrt_div p {
  color: #fff !important;
  display: flex;
  margin-bottom: 0px;
  text-align: left;
}

.white {
  color: #fff;
}


button.btn.btn-primary.loginclass {
  position: relative;
  overflow: hidden;
  background: #2f29a4;
  border-radius: 5px;
  width: 100%;
  height: 49px;
  font-size: 14px;
  font-weight: 600;
  padding: 5px 15px;
  transition: background 0.6s ease;
}

button.btn.btn-primary.loginclass:hover {
  background: #1a73e8;
  /* Change to the desired hover color */
}

button.btn.btn-primary.loginclass::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: #1a73e8;
  /* Change to the desired hover color */
  transition: left 0.6s ease;
}

button.btn.btn-primary.loginclass:hover::before {
  left: 100%;
}


.p80 {
  padding: 80px 0px;
}


.dynamix_name {
  text-transform: capitalize;
}

.contact-us-bg h2 {
  font-size: 30px;
  font-weight: 600;
  color: #2f29a4;
}


.prgresspills {
  width: 80px;
  display: block;
  text-align: center;
  border-radius: 3px;
  padding: 2px;
  color: #fff;
  font-size: 12px;
}

.accept {
  background-color: #77CA00 !important;
}

.pending {
  background-color: #F76B00 !important;
}

.reject {
  background-color: #BE0000 !important;
  color: #fff !important;
}

.shadowboxbtn {
  box-shadow: 0px 10px 6.1px 0px rgba(0, 0, 0, 0.25);
}

@media (max-width:767px) {


  .footerarea.d-flex.justify-content-between.align-items-center {
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
}

.text_missed {
  width: 70px;
  position: relative;
  display: inline-flex;
  background: #e1e1e1;
  text-align: center;
  border-radius: 5px;
  justify-content: center;
  font-size: 12px;
  text-transform: capitalize;
  padding: 6px 10px;

}


.icon_missed,
.starreview {
  width: 40px;
  position: relative;
  display: inline-flex;
  background: #301fa4;
  text-align: center;
  border-radius: 5px;
  justify-content: center;
  color: #fff;
  padding: 8px 10px;
  margin-left: 15px;
  font-size: 12px;
  text-transform: capitalize;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  cursor: pointer;
}


.pagination>li.active a:hover {
  color: #fff !important;
}


.accept {
  background-color: green;
}


.missed {
  background-color: rgb(228, 120, 20);
}

.pending {
  background-color: rgb(216, 255, 42);
}

.terminated {
  background-color: rgb(216, 79, 79);
}


.text_centered p {
  height: 270px;
}
.text20 {
  font-size: 18px;
  font-weight: 600;
  color: #fff;
}

.table-appointment .table td {
  padding: 15px 14px !important;
  vertical-align: middle;
  height: 40px;
}

span.name_div {
  white-space: nowrap;
}

span.name_divth {
  white-space: nowrap;
}

table tr td:first-child {
  text-align: left;
}

table.table tr {
  text-align: center;
}






// .content  i {
//   color: #FFC107;
// }
// .content span {
//   position: relative;
//   display: inline-block;
// }
// .content  span:before, .content  span:after {
//   position: absolute;
//   content: "";
//   background-color: #282828;
//   width: 40px;
//   height: 2px;
//   top: 40%;
// }
// .content  span:before {
//   left: -45px;
// }
// .content  span:after {
//   right: -45px;
// }

// .wrapper_stars {
//   position: relative;
//   display: inline-block;
//   border: none;
//   font-size: 14px;
//   margin: 0px auto;
// }

// .wrapper_stars input {
//   border: 0;
//   width: 1px;
//   height: 1px;
//   overflow: hidden;
//   position: absolute !important;
//   clip: rect(1px 1px 1px 1px);
//   clip: rect(1px, 1px, 1px, 1px);
//   opacity: 0;
// }

// .wrapper_stars label {
//   position: relative;
//   float: right;
//   color: #C8C8C8;
// }

// .wrapper_stars label:before {
//   margin: 5px;
//   content: "\f005";
//   font-family: 'Font Awesome 5 Free';
//   display: inline-block;
//   font-size: 1.5em;
//   font-weight: 900;
//   color: #ccc;
//   -webkit-user-select: none;
//   -moz-user-select: none;
//   user-select: none;
// }

// .wrapper_stars input:checked ~ label:before {
//   color: #FFC107;
// }

// .wrapper_stars label:hover ~ label:before {
//   color: #ffdb70;
// }

// .wrapper_stars label:hover:before {
//   color: #FFC107;
// }

//////////##### Marketing New Pgae css end ///////////#####
/// 
/// 
/// 
/// Congratulations page css
/// 
///
.img-fluid.reward_logo {
  max-width: 20%;
}

.cong_header {
  color: green;
  font-size: 40px;
  font-weight: 200;
}

.cong_desc {
  font-size: 25px;
}

.cong_payment {
  font-size: 25px;
  font-weight: 300;
}

.cong_main_div {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset;
  padding: 30px 40px;
}

.cong_total_amount {
  font-size: 14px;
}

.ok_button {
  border: none;
  /* background: #2727ed; */
  /* color: #fff; */
  padding: 2px 30px;
  border-radius: 5px;
  margin-top: 20px;
}



.wauto {
  width: auto;
  margin: 0 auto 20px;
  height: 60px;
}



.text_centered h4 {
  font-size: 18px;
  font-weight: 500;
  color: #141414;
}

.overf_scrol {
  height: 400px;
  overflow-y: auto;
}

.nav_bg_add {
  background-color: #2e2da2;
  height: 73px;
}

.drophead {
  text-align: right;
  margin-right: 10px;
  color: #fff;
}


.sec-title-plan h2 {
  position: relative;
  display: block;
  font-size: 30px;
  line-height: 1.28em;
  color: #fff;
  font-weight: 600;
  padding-bottom: 18px;
}


.sec-title-plan h2:before {
  position: absolute;
  content: '';
  left: 0px;
  bottom: 0px;
  width: 50px;
  height: 3px;
  background-color: #fff;
}

.sec-title-plan.text-center h2:before {
  left: 50%;
  margin-left: -25px;
}


.align_set {
  border: 1px solid #c9c9c9;
  padding: 20px 10px;
  border-radius: 5px;
  text-align: center;
  margin-bottom: 30px;
  height: 100%;
}
.jhovering:hover{
  color: #fff !important;
  text-decoration: underline;
}
.fonts13 {
  font-size: 13px;
}

.fonts14 {
  font-size: 14px ;
  font-weight: 600 !important;
}


.selected {
  background: #a1d6f1;
}

.selected h4 {
  color: #141414 !important;
}

.selected p {
  color: #141414 !important;
}

h4.price_main {
  background: white;
  width: 50%;
  margin: 0 auto;
  border-radius: 40px;
  font-size: 20px;
  padding: 5px;
}

.selected h4.price_main {
  background: #000;
  color: #fff !important;
}

.payment-form .btn.disabled, .btn:disabled, fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  border: 0 !important;
}

.package_btn{
  border: 0;
  background-color: transparent;
}

.pack_data{
  margin-top: 15px;
}

.plinetata p{
  font-size: 15px;
  font-weight: 600;
}

.height_statick{
  height: 250px;
}

.imgreminder{
  width: auto;
  height: 200px;
}

.timer_chat span{
  font-size: 16px;
  font-weight: 600;
}
@media(max-width:480px) {
  .cong_header {
    font-size: 25px;
  }

  .cong_desc {
    font-size: 15px;
    text-align: center;
  }

  .cong_total_amount {
    font-size: 10px;
    text-align: center;
  }

  .justify-content-between {
    flex-direction: column;
  }

  .cong_payment {
    font-size: 20px;
  }

  .cong_main_div {
    padding: 30px 15px;
  }

  .footer_cong {
    font-size: 12px;
  }
}