.phone_img img {
    width: auto;
    margin-right: 10px;
}
.phone_img {
    display: flex;
    margin-right: 40px;
}
.profile_email {
    display: flex;
    margin: 30px 0px; 
}

.emailname span {
    font-size: 12px;
}

.title_head h6 {
    font-size: 30px;
    font-weight: 600;
}

.bluecolor {
    color: #2f29a4;
}

.buttonvideo button {
    margin-right: 20px;
    width: 200px;
}

.buttonvideo span {
    margin-right: 10px;
}


.bgchat{
    background: #303030 !important;
    border-color: #303030 !important;
}

.bgschdeule{
    background: #838181 !important;
      border-color: #838181 !important;
}

.bggrey-apmt {
    padding: 20px;
    background: #F1F1F1;
    border-radius: 10px;
}

.labelnames{
    margin-bottom: 10px;
}

.h5class {
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 15px;
}

.appointment_head {
    padding: 50px 40px;
    background: #F1F1F1;
    border-radius: 10px;
}

.bgblue_more {
    background-color: #2f29a4;
    padding: 60px 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
     border-radius: 10px;
}

.img_hours img {
    height: inherit;
    width: 100%;
    object-fit: cover;
    object-position: top;
}

.img_hours {
    height: 450px;
    width: 100%;
}


.labelnames .form-control {
    height: 49px;
}

.fomr_fileds {
    margin-top: 30px;
}

.btnblack{
    background-color: #141414 !important;
}


.questo_text h6{
    font-size: 35px;
}

/* pricing table */

.pricing-box {
  -webkit-box-shadow: 0px 5px 30px -10px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 5px 30px -10px rgba(0, 0, 0, 0.1);
  padding: 35px 50px;
  border-radius: 20px;
  position: relative;
}

.pricing-box .plan {
  font-size: 34px;
}

.pricing-badge {
  position: absolute;
  top: 0;
  z-index: 999;
  right: 0;
  width: 100%;
  display: block;
  font-size: 15px;
  padding: 0;
  overflow: hidden;
  height: 100px;
}

.pricing-badge .badge {
  float: right;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  right: -67px;
  top: 17px;
  position: relative;
  text-align: center;
  width: 200px;
  font-size: 13px;
  margin: 0;
  padding: 7px 10px;
  font-weight: 500;
  color: #ffffff;
  background: #060066;
}
.mb-2, .my-2 {
    margin-bottom: .5rem!important;
}
p {
    line-height: 1.7;
}


.pricing-box-bg {
    color: #fff;
    background: #2f29a4 !important;
}

.btnwhited{
    background-color: #F1F1F1 !important;
    color: #000 !important;
}

.blueline{
    font-size: 30px;
}


.blueline:after {
    content: "";
    position: absolute;
    height: 3px;
    width: 54px;
    background: #2f29a4;
    left: 0;
    right: 0;

    margin: 0 auto;
    bottom: -26px;
}


.blueline_20{
    font-size: 24px;
}


.blueline_20:after {
    content: "";
    position: absolute;
    height: 3px;
    width: 54px;
    background: #fff;
    left: 0;
    right: 0;

    margin: 0 auto;
    bottom: -26px;
}



.bgblueradius {
    background: #2f29a4;
    color: #fff;
}



.bgblueradius:after {
    content: "";
    height: 4.5rem;
    z-index: 1;
    background: url("../../../public/assets/img/therpy/shape.png") no-repeat;
    background-size: 100% 100%;
    margin-bottom: 0px;
    position: absolute;
    top: -1px;
    left: 0;
    transform: rotateX(180deg);
    right: 0;
}

.pt130 {
    padding: 130px 0px 80px;
}

.footerarea img {
    width: auto;
    height: 50px;
}

.table_search{
    width: 300px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    height: 40px;
}

select.table_select {
    height: 40px;
    border-radius: 5px;
    background: #fff;
    border: 1px solid #ccc;
    font-size: 14px;
   padding: 6px 30px 6px 15px;
     appearance: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
       background-repeat: no-repeat;
    background-position: right 14px center;
    background-size: 14px;
    color: #403c3c;
}

.table_select:focus-visible {
    outline: -webkit-focus-ring-color auto 0px;
}


.table_search:focus-visible {
    outline: -webkit-focus-ring-color auto 0px;
}







:where(.css-dev-only-do-not-override-18iikkb).ant-picker .ant-picker-input {
    position: relative;
    display: inline-flex;
    align-items: center;
    width: 100%;
    height: 100%;
}

@media (max-width:767px){
    .profile_email {
    display: flex;
    margin: 30px 0px;
    flex-direction: column;
    row-gap: 20px;
}

.appointment_head {
    padding: 39px 20px;
    background: #F1F1F1;
    border-radius: 10px;
}

.profile_data {
    margin: 20px 0px;
}

.buttonvideo button {
    width: 100% !important;
    margin-bottom: 18px;
}

.bg_leaf .leftside {
  
    width: 150px;
}
.phone_img img {
    width: auto;
    margin-right: 10px;
    height: 100%;
}
.disc_icon {
    padding: 15px 40px;
}
span.dynamix_name {
font-size: 30px;
}
.textblue {
    font-size: 35px;
    display: block;
}
}

.timelabel .dropdown-toggle::after {
    display: inline-block;
    margin-left: auto !IMPORTANT;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
    position: absolute;
    right: 9px;
    top: 17px;
}


.timelabel .dropdown-menu.show {
    display: block;
    z-index: 99999 !important;
    column-count: 3;
    width: 100%;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

button.btn.btn-primary.text-left.parimarybtn.dropdown-toggle.mb-2 {
    color: #4b5057 !IMPORTANT;
}

.timelabel .btn:hover{
    border: 0 !important;
}

.timelabel .parimarybtn{
    height: 49px;
}

.h60{
    height: 40px;
}

/* rating csss */



  #full-stars-example-two .rating-group {
    display: inline-flex;
  }
  #full-stars-example-two .rating__icon {
    pointer-events: none;
  }
  #full-stars-example-two .rating__input {
    position: absolute !important;
    left: -9999px !important;
  }
  #full-stars-example-two .rating__input--none {
    display: none;
  }
  #full-stars-example-two .rating__label {
    cursor: pointer;
    padding: 0 0.1em;
    font-size: 2rem;
  }
  #full-stars-example-two .rating__icon--star {
    color: orange;
  }
  #full-stars-example-two .rating__input:checked ~ .rating__label .rating__icon--star {
    color: #ddd;
  }
  #full-stars-example-two .rating-group:hover .rating__label .rating__icon--star {
    color: orange;
  }
  #full-stars-example-two .rating__input:hover ~ .rating__label .rating__icon--star {
    color: #ddd;
  }
  
