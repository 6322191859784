.loginimg {
    width: 100%;
}

button.btn.btn-outline.socialBtn img {
    width: 16px;
}



.logo_login img {
    width: 220px;
    margin-bottom: 2rem;
    margin-top: 2rem;
}



.borderCls {
    height: 1px;
    width: 100%;
    background: #DCDCDC;
    text-align: center;
}

.inner_login input {
    background: transparent;
    border: 0px !important;
    border-bottom: 1px solid #ccc !important;
    border-radius: 0px !important;
    padding: 0px !important;
}

.inner_login input:focus {
    color: #495057;
    background-color: transparent !important;
    border-bottom: 1px solid rgb(112, 110, 248) !important;
    outline: 0;
    box-shadow: none !important;
}

.w80 {
    width: 80%;
    position: relative;
    top: 60px;
}

span.or {
    position: relative;
    top: -12px;
    background: #fff;
    padding: 10px;
    color: #898989;
}

form.p-3.rounded.shadow {
    border-top: none;
}

img.loginimg.w-100 {
    height: 100%;
    object-fit: cover;
}

form.centerLogin {
    width: 418px;
    display: block;
    margin: auto;
}

img.logimg {
    width: 100%;
    max-width: 167px;
    max-height: 100px;
    object-fit: cover;
}


.login-wrapper {
    .mainfromclss {
        max-width: 900px;
    }
}

img.logo {
    width: 300px;
    height: auto;

}

.logo_img {
    padding: 0rem 3rem;
}

.banner_img_local {
    background-image: url('../../../public/assets/img/therpy/sign-in.jpg') !important;
    height: 100vh;
    width: 100%;
    // background-size: 100%;
    background-position: left center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 4rem 0rem;
}

.loginLeftImg h3 {
    position: absolute;
    bottom: 10px;
    font-size: 36px;
    font-weight: 600;
    line-height: 50px;
    color: #fff;
    padding: 4rem 2rem;


}

.right_side {
    height: 100vh;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    overflow: auto;
    padding: 2rem 0px;
}

p.accopunt {
    text-align: right;
    position: absolute;
    right: 55px;
    padding: 4rem 1rem;
    font-size: 14px;
    font-weight: 400;
    top: 31px !important;
}

p.accopunts {
    // position: absolute;
    padding: 0rem 1rem;
    font-size: 13px;
    font-weight: 400;
    width: 100%;
    text-align: left;
    bottom: 0;
    max-width: 454px;
    color: #84818A;
    word-spacing: 3px;
}

a.sign_up {
    text-decoration: none;
}

h3.text-left.lgtext {
    font-weight: 600;
    font-size: 24px;
}

.bginput {
    border-radius: 8px !important;
    border: 1px solid #cad6ff !important;
    height: 40px !important;
}

a:hover {
    color: #2f29a4 !important;
}

a.text-primary:hover,
a.text-primary:focus {
    color: #2f29a4 !important;
    text-decoration: none;
}



.forget {
    text-align: left;
    margin: 1rem 0px;
}

.title-around-span {
    position: relative;
    text-align: center;
    color: #484848;
    font-size: 14px;

    &:before {
        content: "";
        display: block;
        height: 1px;
        width: 100%;
        position: absolute;
        left: 0;
        top: 50%;
        background-color: #E8E8E8;
    }

    span {
        position: relative;
        z-index: 1;
        padding: 0 15px;
        background: #ffff;
    }
}


.google_logo {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
}

img.gogle_img {
    width: 30px;
    height: 30px;
    margin: 0px 5px;
}

img.gogle_imgs {
    width: 25px;
    height: 24px;
    margin: 0px 5px;
}

a.btn.btn-outline.google_id img {
    width: 16px;
    margin-right: 10px;
}

.forget label {
    color: #2f29a4;
    cursor: pointer;
    font-weight: 400;
    font-size: 14px;
}

.google_id {
    border: 1px solid #E8E8E8;
    width: 45% !important;
    height: 48px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
}

.google_heading {
    font-size: 16px;
    font-weight: 600;
    color: #202020;

}

.google_id {
    width: 178px;
    border: 1px solid #E8E8E8;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
}

.checkBox {
    position: relative;
    top: 4px;
}

.clickBox {
    color: #898989;
    font-size: 12px;
}


.width50_auto {
    padding: 40px;
    border-radius: 10px;
    // box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.width50_auto_boxshaodow {
    padding: 40px;
    border-radius: 10px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.centerbody {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}


@media only screen and (min-width:1001px) and (max-width:1060px) {
    img.logo {
        width: 200px !important;
        height: auto;
    }

    .logo_img {
        padding: 0rem 2rem;
    }

    .loginLeftImg h3 {
        position: absolute;
        bottom: 10px;
        font-size: 29px !important;
        line-height: 53px !important;
        color: #fff;
        padding: 2rem 2rem;
    }
}

@media only screen and (min-width:768px) and (max-width:1000px) {
    img.logo {
        width: 200px !important;
        height: auto;
    }

    .logo_img {
        padding: 0rem 2rem;
    }

    .loginLeftImg h3 {
        position: absolute;
        bottom: 10px;
        font-size: 29px !important;
        line-height: 35px;
        color: #fff;
        padding: 2rem 2rem;
    }


}



@media only screen and (min-width:320px) and (max-width:767px) {
    .banner_img_local {
        display: none;
    }

    .right_side {
        padding: 32px;
    }

    img.logos {
        width: 200px;
    }

    .login_img {
        text-align: center;
        position: absolute;
        display: block !important;
        top: 44px;
    }

    form.centerLogin {
        margin: 4rem auto !important;
    }

    p.accopunt {
        top: 116px !important;
    }

}