.main12 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.backcls {
    background-image: url('https://wallpapers.com/images/hd/virtual-meeting-background-srj07387lg3e219s.jpg');
    background-position: 100% 100%;
    height: 56vh;
}