.bg-theripst-img {
  background: url("/public/assets/img/therpy/therpiestimg.png") no-repeat;
  background-position-y: bottom;
  background-size: cover;
  background-color: #2e31a5;
}


.bg-theripst-img:before {
    content: "";
    height: 4.5rem;
    z-index: 1;
    background: url("/public/assets/img/therpy/shape.png") no-repeat;
    background-size: 100% 100%;
    margin-bottom: -1px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

.heaigt100_center{
    height: 100%;
    padding: 80px 0px 120px;
}
 
.label_answer_therpist {
    min-height: 60px; 
    background: #8589FF;   
    color:#fff; 
    font-weight: 600;
    font-size:18px;
}

.label_answer:hover {
    background: #3224bf;
    color: #fff;
}

.label_answer_therpist p {
    font-size: 12px;
    font-weight: 400;
}



.width60{
    width: 60%;
    margin: 0 auto;
}

.theripst_head h2 {
    color: #fff;
    font-size: 35px;
    font-weight: 500;
}

.bold_white{
font-weight: 600;
}

.theripst_head p {
    color: #fff;
    font-size: 18px;
}


.gap_ing img {
    width: 30px;
    margin-right: 15px;
}

.gap_ing {
    display: flex;
    margin-bottom: 20px;
    align-items: center;
}

.gap_ing p {
  margin: 0px;
}

.radio_custom label {
    margin-right: 15px;
}

.radio_fulltime {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin: 30px 0px 20px;
}

.rao_rights {
    margin-left: 25px;
}

.slide_inner H3 {
    font-size: 25px;
}


span.radio_custom span {
    margin-right: 10px;
}

.radio_fulltime p {
    margin-bottom: 0;
}

/* range slider */
 .custom_range {
  outline: 0;
  border: 0;
  border-radius: 50px;
  width: 100%;
  max-width: 100%;
  margin: 24px 0;
  transition: box-shadow 0.2s ease-in-out;
}

.custom_range {
    overflow: hidden;
    height: 20px;
    -webkit-appearance: none;
    background-color: #ddd;
  }
  .custom_range::-webkit-slider-runnable-track {
    height: 20px;
    -webkit-appearance: none;
    color: #444;
    margin-top: -1px;
    -webkit-transition: box-shadow 0.2s ease-in-out;
    transition: box-shadow 0.2s ease-in-out;
  }
  .custom_range::-webkit-slider-thumb {
    width: 20px;
    -webkit-appearance: none;
    height: 20px;
    cursor: ew-resize;
    background: #fff;
    box-shadow: -340px 0 0 320px #3224bf, inset 0 0 0 40px #3224bf;
    border-radius: 50px;
    -webkit-transition: box-shadow 0.2s ease-in-out;
    transition: box-shadow 0.2s ease-in-out;
    position: relative;
  }
  .custom_range:active::-webkit-slider-thumb {
    background: #fff;
    box-shadow: -340px 0 0 320px #3224bf, inset 0 0 0 3px #3224bf;
  }

 .custom_range::-moz-range-progress {
  background-color: #000;
}
 .custom_range::-moz-range-track {
  background-color: #9a905d;
}
 .custom_range::-ms-fill-lower {
  background-color: #43e5f7;
}
 .custom_range::-ms-fill-upper {
  background-color: #9a905d;
}


.slider_range_theripst {
    padding: 40px;
    margin: 20px;
   background: rgba(232, 232, 255, 0.38);
   border-radius: 10px;
}

.slide_inner h3 {
    font-size: 20px;
}

.range_laysot {
    margin-bottom: 30px;
}

.headingh4{
    font-size: 20px;
    font-weight: 600;
}

.font300{
    font-weight: 400;
}

.ulclass li{
    padding: 10px 0px 15px;
}

.icons-text {
    margin-bottom: 30px;
}

.shapebeforeafter:before{
      content: "";
    height: 4.5rem;
    z-index: 1;
    background: url("/public/assets/img/therpy/shape.png") no-repeat;
    background-size: 100% 100%;
    margin-bottom: -1px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

.shapebeforeafter:after {
    content: "";
    height: 4.5rem;
    z-index: 1;
    background: url(http://localhost:8053/static/media/shape.cc9d22abab7343abf5a4.png) no-repeat;
    background-size: 100% 100%;
    margin-bottom: 0px;
    position: absolute;
    top: -1px;
    left: 0;
    transform: rotateX(180deg);
    right: 0;
}


.table_cusomt .table thead th {
    border: 1px solid transparent !important;
    background: transparent;
    padding: 20px !important;
    color: #fff;
}
.table_cusomt table.table thead .first-column {
    width: 40%;
}

.table_cusomt table.table .table-comparison-icon {
    text-align: center;
    width: 20%;
}

.table_cusomt tr, thead tr {
    width: 100%;
    margin-bottom: 1rem;
    color: #fff;
    background: transparent !important;
}

.table_cusomt table.table tbody tr:first-child {
    border-top: 0;
}

.table-comparison-text {
    color: #fff;
    border-bottom: 1px solid #ccc !important;
    font-size: 20px;
}

.table_cusomt .table td {
    border-bottom: 1px solid #ccc !important;
    padding: 20px 0px !important;
    background: transparent;
    color: #fff;
}

 .table td {
 border: 0px !important;
}

.table_cusomt table{
    border: 0;
}

.imglogo img {
    width: 50%;
    margin: 0 auto;
}

.logo-therpy  {
    
    color: #fff;
}

.last-column{
    text-align: center;
    color: #fff;
}

h2.h2class {
    font-size: 25px;
}

.table_cusomt {
    padding: 60px 0px 80px;
}

.table_head {
    margin-bottom: 60px;

}


.logo-therpy {
    background: #2f29a4 !IMPORTANT;
    border-radius: 20px 20px 0px 0px;
}

.bg_blue{
    background-color: #030666;
}

.table-comparison-icon.active_table {
    background: #020683 !important;
    border-bottom: 1px solid #020683 !important;
}


.textquest h4 {
    font-size: 30px;
}

.textquest h4 span {
    font-size: 30px;
}

.textquest img {
    margin-bottom: 20px;
}

span.bluetext {
    color: #2f29a4;
    font-weight: 600;
}

.bluetext {
    font-size: 25px;
    color: #2f29a4;
    margin-bottom: 30px;
}

.reuints_text ul li {
    padding: 10px 0px 2px;
}

.reuints_text ul {
    border-bottom: 1px solid #2f29a4;
    padding-bottom: 20px;
}

.disc_icon img {
    width: 80px;
}

.bluecolor{
    color: #2f29a4;
}

.footerarea p {
    font-size: 16px;
}

@media (max-width:767px){
    .width60 {
    width: 100%;
}
.footerarea p {
    font-size: 16px;
    margin-bottom: 30px !IMPORTANT;
}
}