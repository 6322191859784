.people-list {
  width: 30%;
  float: left;
  background: #efefef;
  border-radius: 10px 0px 0px 10px;
}

.people-list .search {
  padding: 20px;
}

.people-list input {
  border-radius: 3px;
  border: none;
  padding: 14px;
  color: white;
  background: #ffffff;
  width: 90%;
  font-size: 14px;
}

.people-list .fa-search {
  position: relative;
  left: -25px;
}

.people-list ul {
  padding: 20px;
  height: 400px;
  list-style: none;
  overflow-y: scroll;
}

.people-list ul li {
  padding-bottom: 20px;
}

.people-list img {
  float: left;
}

.people-list .about {
  float: left;
  margin-top: 8px;
}

.people-list .about {
  padding-left: 8px;
}

.people-list .status {
  color: #92959E;
}


.status {
  display: inline-block;
  padding: 0px;
  border-radius: 4px;
  font-weight: 400;
  background-color: #f2f5f8;
}

li.clearfix img {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 50px;
}

.chat {
  width: 100%;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background: #fff;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  color: #434651;
}


.chat .chat-header {
  padding: 20px;
  border-bottom: 2px solid white;
  background-color: #ececec;
}

.chat .chat-header img {
  float: left;
}


.chat .chat-header .chat-about {
  float: left;
  padding-left: 10px;
  margin-top: 6px;
}

.chat .chat-header .chat-with {
  font-weight: bold;
  font-size: 16px;
}

.chat .chat-header .chat-num-messages {
  color: #92959E;
}

.chat .chat-header .fa-star {
  float: right;
  color: #D8DADF;
  font-size: 20px;
  margin-top: 12px;
}

.chat .chat-history {
  padding: 30px 30px 20px;
  border-bottom: 2px solid white;
  overflow-y: auto;
  height: 450px;
}

.chat .chat-history .message-data {
  margin-bottom: 15px;
}

.chat .chat-history .message-data-time {
  color: #a8aab1;
  padding-left: 6px;
}

.chat .chat-history .message {
  color: white;
  padding: 18px 20px;
  line-height: 26px;
  font-size: 16px;
  border-radius: 7px;
  margin-bottom: 20px;
  width: 50%;
  position: relative;
  line-break: anywhere;
}

.chat .chat-history .message:after {
  bottom: 100%;
  left: 7%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-bottom-color: #301fa496;
  border-width: 10px;
  margin-left: -10px;
}

.chat .chat-history .my-message {
  background: #301fa496;
}

.chat .chat-history .other-message {
  background: #2f29a4;
}

.chat .chat-history .other-message:after {
  border-bottom-color: #2f29a4;
  left: 93%;
}

.chat .chat-message {
  padding: 30px;
  background: #ececec;
}

.chat .chat-message textarea {
  width: 100%;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  resize: none;
  border: 1px solid #2f29a4;
}

.chat .chat-message .fa-file-o,
.chat .chat-message .fa-file-image-o {
  font-size: 16px;
  color: gray;
  cursor: pointer;
}

.chat .chat-message button {
  float: right;
  color: #2f29a4;
  font-size: 16px;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  font-weight: bold;
}

.chat .chat-message button:hover {
  color: #75b1e8;
}

.online,
.offline,
.me {
  margin-right: 3px;
  font-size: 10px;
}

.online {
  color: #301fa496;
}

.offline {
  color: #E38968;
}

.me {
  color: #2f29a4;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.float-right {
  float: right;
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

.chat-history ul {
  list-style: none;
}

.icon_blue {
  color: #2f29a4 !important;
  font-size: 25px;
  margin-left: 10px;
}

.image_loader {
  text-align: center;
  padding: 30px 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f0e5e5;
  height: 40vh;
  margin-left: auto;
  border-radius: 10px;
  position: relative;
  top: -34px;
  width: 30%;
  margin-right: 30px;
  border: 1px solid #ccc;
}

/* Show loader when the image is loading */
.loading .loader {
  display: block;
}

/* Show image when it's loaded */
.loaded .image {
  display: block;
}

.clearfix img {
  height: 50px;
  border-radius: 50px;
}

.textrea_list {
  height: 45px;
}

.pointer_upload {
  position: absolute;
  right: 20px;
  top: 45%;
  transform: translateY(-50%);
}

.pointer_upload i.fa.fa-paperclip {
  color: grey;
}


.textrea_list:focus-visible {
  outline: #2f29a4 auto 1px;
}



.pdf_inner_layout {
  text-align: center;
}


span.pdficon i {
  font-size: 45px;
}

.hoverdonload {
  display: none;
  position: absolute;
  top: 50%;
  left: 35%;
  transform: translateY(-12px);
}

.pdf_btn:hover .hoverdonload {
  display: block !IMPORTANT;
}

.pdf_btn:hover .hoverdonload i {
  color: #fff;
  font-size: 20px;
}


.pdf_btn:hover .hoverdonload {
  display: block !IMPORTANT;
}

.pdf_btn {
  background-color: #ededed;
  padding: 15px 10px;
  border-radius: 10px;
  margin-top: 10px;
  width: 30%;
  position: relative;
}

.btn_download_chat a {
  background: #f14620;
  padding: 5px;
  margin-top: 10px;
  display: block;
  width: 100%;
  text-align: center;
  border-radius: 10px;
  color: #fff;
  font-size: 14px;
}

.btn_download_chat a:hover {
  color: #fff;
}



.img_profile_u {
  width: 100%;
}


.text_missed {
  width: 70px;
  position: relative;
  display: inline-flex;
  background: #e1e1e1;
  text-align: center;
  border-radius: 5px;
  justify-content: center;
  font-size: 12px;
  text-transform: capitalize;
  padding: 6px 10px;

}


.icon_missed,
.starreview {
  width: 40px;
  position: relative;
  display: inline-flex;
  background: #301fa4;
  text-align: center;
  border-radius: 5px;
  justify-content: center;
  color: #fff;
  padding: 8px 10px;
  margin-left: 15px;
  font-size: 12px;
  text-transform: capitalize;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  cursor: pointer;
}


.pagination>li.active a:hover {

  color: #fff !important;
}

span.counting_msg {
  background: #fff;
  border-radius: 50px;
  height: 20px;
  width: 20px;
  color: #141414;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  padding: 2px;
  right: 5px;
  top: -12px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}