.dropdownOverlap{
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top:0;
    display: none;
    z-index: 999;
  }
  .daterangeDropdown{
    z-index: 9999;
  }

  .rdrDateDisplayWrapper{
    display: none;
  }
  .cld_btn button {
    width: 47%;
}