/* .header-dark-page .navbar-light .navbar-nav .nav-link {
    color: #141414;
    margin-right: 15px;
} */

.header-dark-page .navbar-light .navbar-nav .active>.nav-link,
.header-dark-page .navbar-light .navbar-nav .nav-link.active,
.header-dark-page .navbar-light .navbar-nav .nav-link.show,
.header-dark-page .navbar-light .navbar-nav .show>.nav-link {
    /* color: #2f29a4 !important; */
    border-bottom: 1px solid #fff;
    font-weight: 600;
}

.header-dark-page .navbar-light .navbar-nav .nav-link:hover {
    color: #141414;
}

.header-dark-page .btn.btn-white-tranparent {
    /* border: 2px solid #2f29a4; */
    border-radius: 50px;
    /* color: #141414; */
    font-weight: 400;
}

.header-dark-page .btn.btn-white-tranparent:hover {
    border: 2px solid #2f29a4;
    background-color: #2f29a4;
    border-radius: 50px;
    color: #fff;
    font-weight: 400;
}


.header-dark-page .btn.btn-white {
    background-color: #2f29a4;
    border-radius: 50px;
    color: #fff;
    font-weight: 400;
}

.header-dark-page .btn.btn-white {
    /* background-image: linear-gradient(to right, #4f54da 0%, #4f54da 50%, transparent 50%, transparent 100%); */
    background: #fff;
    background-position: 100% 0;
    background-size: 200% 100%;
    transition: background-position 0.3s ease;
    border-radius: 50px;
    color: #2e2da2 !important;
    font-weight: 400;
}

.header-dark-page .btn.btn-white:hover {
    background-position: 0 0;
}







.header-dark-page {
    transition: all 0.3s ease;
}



.scrollsticky {
    background-color: #2e2da2;
    position: sticky;
    top: 0;
    z-index: 9;
    box-shadow: 1px 0px 9px #e1dddd;

}




.bg_leaf .leftside {
    position: absolute;
    left: 0;
    width: 300px;
}

.bg_leaf .rightside {
    position: absolute;
    right: 0;
    width: 300px;
}

.h2title {
    color: #2f29a4;
    font-size: 2rem;
    font-weight: 600;
}

.h3class {
    color: #141414;
    font-weight: 500;
    font-size: 1.225rem;
}

.ptext {
    color: #141414;
    font-weight: 500;
    font-size: 1rem;
    width: 80%;
    margin: 15px auto;
}

.p-60 {
    padding: 3.5rem 0rem;
}

.starting_quest {
    background: #f1f1f1;
    padding: 30px;
    border-radius: 10px 20px;
    margin-bottom: 100px;
    margin-top: 2rem;
}

.label_answer {
    width: 100%;
    background: #fff;
    padding: 10px 20px;
    color: #141414;
    font-size: 15px;
    border-radius: 10px;
    font-weight: 400;
    cursor: pointer;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
}

.hideradio:checked~label {
    background-color: #2f30bf;
    color: #fff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.answing_list {
    padding: 15px 0px;
}


.answing_list .onetworadio {
    padding-bottom: 10px;
}


.disc_icon {
    background: #D7D8FF;
    padding: 15px 20px;
    border-radius: 10px;
    display: flex;
    column-gap: 15px;
    align-items: center;
    justify-content: center;
}

.disc_icon i {
    background: #2f29a4;
    padding: 10px;
    border-radius: 50px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    color: #fff;
}

.disc_icon span {
    color: #141414;
    line-height: 15.5px;
    font-size: 13px;
}

/* next js page */

.bg_light_grey {

    background: #eeedff;
}

.nextjs_banner {
    padding: 50px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.hewi25 {
    width: 25px;
    height: 25px;
}


.wrap_text_onbord h6 {
    font-size: 20px;
    margin-bottom: 15px;
    font-weight: 600;
}

.wrap_text_onbord p {
    font-size: 15px;
}


.wrap_text_onbord {
    margin-bottom: 40px;
}


.payment-form.mt-4 {
    background: #D7D8FF;
    padding: 50px;
    border-radius: 10px;
}



/* pricing table */
.pricing-box {
    -webkit-box-shadow: 0px 5px 30px -10px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 5px 30px -10px rgba(0, 0, 0, 0.1);
    padding: 20px 30px;
    border-radius: 20px;
    position: relative;
    background-color: #fff;


}

.pricing-box .plan {
    font-size: 34px;
}

.pricing-badge {
    position: absolute;
    top: 0;
    z-index: 999;
    right: 0;
    width: 100%;
    display: block;
    font-size: 15px;
    padding: 0;
    overflow: hidden;
    height: 100px;
}

.pricing-badge .badge {
    float: right;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    right: -67px;
    top: 17px;
    position: relative;
    text-align: center;
    width: 200px;
    font-size: 13px;
    margin: 0;
    padding: 7px 10px;
    font-weight: 500;
    color: #ffffff;
    background: #060066;
}

.mb-2,
.my-2 {
    margin-bottom: .5rem !important;
}

p {
    line-height: 1.7;
}


.pricing-box-bg {
    color: #fff;
    background: #2f29a4 !important;
}

.btnwhited {
    background-color: #F1F1F1 !important;
    color: #000 !important;
}

.blueline {
    font-size: 30px;
}


.blueline:after {
    content: "";
    position: absolute;
    height: 3px;
    width: 54px;
    background: #fff !important;
    left: 0;
    right: 0;

    margin: 0 auto;
    bottom: -26px;
}

.blueline1:after {
    content: "";
    position: absolute;
    height: 3px;
    width: 54px;
    background: #2f29a4 !important;
    left: 0;
    right: 0;

    margin: 0 auto;
    bottom: -26px;
}

.blueline1 {
    font-size: 30px;
}

.pading140 {
    padding: 130px 0px 150px;
}

.activebg {
    background-color: #2f29a4 !important;
    color: #fff !important;
}

section#pricing .activebg .pricing-plan .text-dark,
section#pricing .activebg .pricing-plan s,
section#pricing .activebg .pricing-plan .text-muted {
    color: #fff !important;
}



section#pricing .activebg .custom-radio input:checked+.radio-icon:after {
    color: #000;
}


.section#pricing .activebg .radio-icon {
    background-color: #f8f8fd;
}

.section#pricing .activebg .btn:active {
    border: 0;
}

.textend {
    text-align: end;
}


.section#pricing .activebg .btn-check:checked+.btn,
.btn.active,
.btn.show,
.section#pricing .activebg .btn:first-child:active,
:not(.btn-check)+.section#pricing .activebg.btn:active {
    color: var(--bs-btn-active-color);
    background-color: transparent !important;
    border-color: transparent !important;
}

.section#pricing .btn:active {
    border: 0;
}

.section#pricing .btn {
    border: 0;
}



/* .overflowscroll {
    min-height: 100px;
    max-height: 100px;
    height: 100%;
    overflow: hidden;
} */


/* .overflowscroll:hover {
    overflow-y: scroll;
} */

.imgtag img {
    width: 80%;
    margin: 0 auto;
}

.btnlarge {
    padding: 10px 50px;
    border: 1px solid #fff !important;
    background-color: #fff !important;
    color: #000 !important;
}

.btnlarge:hover {

    color: #fff !important;
}


/* css by deepak */


.one_line {
    display: flex;
    align-items: center;

}

.form_double {
    width: 500px;
}

.first_column p {
    font-size: 20px;
}

.bnone {
    border-bottom: 1px solid transparent !important;
}


.form_half {
    width: 600px;
    display: flex;
}

.form_double {
    width: 500px;
}

.width50 {
    width: 100%;
    text-align: center;
}

.active_price {
    background: #020683 !important;
    border-bottom: 1px solid #fff !important;
}

.second_column {
    padding: 20px;
    height: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #fff;

}

.pricing_new {
    padding-top: 80px;
}

.first_column {
    padding: 20px;
    border-bottom: 1px solid #fff;

}


.logo-therpy {
    background: #2f29a4 !IMPORTANT;
    border-radius: 20px 20px 0px 0px;

}

.plans_data {
    padding: 25px;
    text-align: center;
    border-radius: 20px 20px 0px 0px;
    color: #fff;
    cursor: pointer;
}