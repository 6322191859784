 .planIcon {
   height: auto;
   width: auto;
   font-size: 16px;
   margin-right: 6px;
 }

 .removeBg {
   background: #fff !important;
   border: 1px solid #ced4da !important;
   color: #495057 !important;
   border-radius: 8px;
   text-align: left;
   width: 100%;
   padding-left: 10px;
   padding-right: 30px;
   position: relative;
 }

 .table_section {
   background: #fff;
   padding: 12px !important;
   border-radius: 12px;
   // margin-top: 32px;
   box-shadow: 0px 0px 2px #cccccc40 !important;
 }

 .bg_hover a.dropdown-item {
   margin-bottom: 3px;
 }

 .dropdown-menu.shadow.bg_hover.show {
   border: none;
   padding: 10px;
   border-radius: 14px;
   padding: 0.7rem 0.5rem;
   max-height: 300px;
   overflow: auto;
   // width: 100%;
 }

 .bg_hover .dropdown-item:focus,
 .dropdown-item:hover {
   background-color: #D0D5DD;
   color: #765aa6 !important;
   border-radius: 8px;
   padding: 0.25rem 1rem;
 }

 .bg_hover .dropdown-item.active,
 .bg_hover .dropdown-item:active {
   text-decoration: none;
   background-color: #D0D5DD;
   color: #765aa6 !important;
   border-radius: 8px;
   padding: 0.25rem 1rem;
 }

 .borderBasic {
   border: 1px solid #000;
   padding: 20px;
   border-radius: 10px;
   margin-bottom: 20px;
 }

 i.fa.fa-check {
   color: #929292;
 }

 .borderI {
   border: 1px solid #808080;
   font-size: 8px;
   padding: 2px 6px;
   border-radius: 50px;
   color: #808080;
   position: relative;
   bottom: 2px;
   margin-left: 10px;
 }

 tbody.planData tr td {
   color: #7557a6;
 }

 tr.bg-gray td {
   background: #f9f9f9;
 }

 .table.planTable th,
 .table td {
   border-bottom: 1px solid #f3f3f3 !important;
 }

 .table-bordered td,
 .table-bordered th {
   border: 1px solid #f3f3f3 !important;
 }

 // tabs
 .nav-pills .nav-link {
   border-radius: 50px;
   text-align: center;
   width: 120px;
   display: block;
   margin: auto;
 }

 .text-center.tableHeading small {
   color: #353535;
   font-size: 14px;
 }

 .text-center.tableHeading h2 {
   color: #000;
   font-size: 36px;
 }

 .text-center.tableHeading b {
   font-size: 17px;
   color: #000;
   text-transform: capitalize;
 }

 .nav-pills .nav-link.active,
 .nav-pills .show>.nav-link {
   background-color: #765aa6;
   box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);
 }

 .nav-link {
   color: #474747;
 }

 // .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
 //   color: #000;
 //   border:none;
 //   background-color: transparent;
 //   border-bottom: 3px solid #765aa6;
 //   padding-bottom: 9px;
 // }
 // .nav-pills .nav-link:hover{ 
 //   color: #000 !important;
 //   background: transparent; 
 // }
 // ul.nav.nav-pills.mb-3 {
 //   border-bottom: 1px solid #DCDCDC;
 // }
 // .nav-pills .nav-link {
 //   border-radius: 0rem;
 //   color: #898989;
 //   background: transparent;
 //   margin-right: 30px;
 //   padding: 10px 0px;
 //   font-size: 14px;
 // }
 small.monthly {
   font-size: 14px;
 }

 .hedding {
   color: #3C3E49;
   font-weight: 600;
   font-size: 22px;
   line-height: 26px;
   margin: 0px;
 }

 .pricing-content {
   position: relative;
 }

 .pricing_design {
   position: relative;
   margin: 0px;
   height: 100%;
 }

 .pricing_design .single-pricing {
   background: #2f29a4;
   padding: 60px 40px;
   border-radius: 30px;
   box-shadow: 0 10px 40px -10px rgba(0, 64, 128, .2);
   position: relative;
   z-index: 1;
   height: 100%;
 }

 .pricing_design .single-pricing:before {
   content: "";
   background-color: #fff;
   width: 100%;
   height: 100%;
   border-radius: 18px 18px 190px 18px;
   border: 1px solid #eee;
   position: absolute;
   bottom: 0;
   right: 0;
   z-index: -1;
 }

 .price-head {}

 .price-head h2 {
   margin-bottom: 10px;
   font-size: 26px;
   font-weight: 600;
 }

 .price-head h1 {
   font-weight: 600;
   margin-top: 10px;
   margin-bottom: 15px;
 }

 .price-head span {}

 .single-pricing ul {
   list-style: disc;
   margin-top: 30px;
 }

 .single-pricing ul li {
   line-height: 22px;
   text-align: left;
 }

 .single-pricing ul li i {
   background: #2f29a4;
   color: #fff;
   width: 20px;
   height: 20px;
   border-radius: 30px;
   font-size: 11px;
   text-align: center;
   line-height: 20px;
   margin-right: 6px;
 }

 .pricing-price {}

 .price_btn {
   background: #554c86;
   padding: 10px 30px;
   color: #fff;
   display: inline-block;
   margin-top: 20px;
   border-radius: 2px;
   -webkit-transition: 0.3s;
   transition: 0.3s;
 }

 .price_btn:hover {
   background: #0aa1d6;
 }

 a {
   text-decoration: none;
 }

 .section-title {
   margin-bottom: 30px;
 }

 .text-center {
   text-align: center !important;
 }

 .section-title h2 {
   font-size: 36px;
   font-weight: 600;
   margin-top: 0;
   position: relative;
 }

 .form {
   display: flex;
   flex-direction: column;
   gap: 20px;
   padding: 20px;
 }

 .paymentother {
   display: grid;
   grid-template-columns: 50% 50%;
   gap: 20px;
   padding: 10px;
   justify-content: center;
 }

 .paymentother button {
   height: 55px;
   background: #F2F2F2;
   border-radius: 11px;
   padding: 0;
   border: 0;
   outline: none;
 }

 .paymentother button svg {
   height: 18px;
 }

 .paymentother button:last-child svg {
   height: 22px;
 }

 .separator {
   width: calc(100% - 20px);
   display: grid;
   grid-template-columns: 2fr 1fr 2fr;
   gap: 10px;
   color: #8B8E98;
   margin: 0 10px;
 }

 .separator p {
   display: block;
   text-align: center;
   font-weight: 600;
   font-size: 12px;
   margin: auto;
 }

 .separator .line {
   display: inline-block;
   width: 100%;
   height: 1px;
   border: 0;
   background-color: #e8e8e8;
 }

 .card-info {
   display: flex;
   flex-direction: column;
   gap: 15px;
 }

 .input_container {
   width: 100%;
   height: fit-content;
   display: flex;
   flex-direction: column;
   gap: 5px;
 }

 .split {
   display: flex;
   grid-template-columns: 4fr 2fr;
   gap: 15px;
 }

 .split input {
   width: 100%;
 }

 .input_label {
   font-size: 12px;
   color: #8B8E98;
   font-weight: 600;
 }

 .input_field {
   width: auto;
   height: 40px;
   padding: 0 0 0 16px;
   border-radius: 9px;
   outline: none;
   background-color: #F2F2F2;
   border: 1px solid #e5e5e500;
   transition: all 0.3s cubic-bezier(0.15, 0.83, 0.66, 1);
 }

 .input_field:focus {
   border: 1px solid transparent;
   box-shadow: 0px 0px 0px 2px #242424;
   background-color: transparent;
 }

 /* Reset input number styles */
 .input_field::-webkit-outer-spin-button,
 .input_field::-webkit-inner-spin-button {
   -webkit-appearance: none;
   margin: 0;
 }

 .checkout {
   position: relative;
   font-family: inherit;
   font-weight: 500;
   font-size: 18px;
   letter-spacing: 0.05em;
   border-radius: 0.8em;
   border: none;
   background: #302b63;
   color: ghostwhite;
   overflow: hidden;
 }

 .checkout span {
   position: relative;
   transition: color 0.6s;
   display: inline-flex;
   align-items: center;
   padding: 0.8em 1.2em 0.8em 1.05em;
 }

 .checkout::before,
 .checkout::after {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
 }

 .checkout::before {
   content: "";
   background: #000;
   width: 120%;
   left: -10%;
   transform: skew(30deg);
   transition: transform 0.5s cubic-bezier(0.3, 1, 0.8, 1);
 }

 .checkout:hover::before {
   transform: translate3d(100%, 0, 0);
 }

 .checkout:active {
   transform: scale(0.95);
 }

 .table td,
 .table th {
   border-top: 1px solid #ffffff !important;
 }


 // tooltip