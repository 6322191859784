/* header css */

.header_main .navbar-light .navbar-nav .nav-link {
  color: rgba(255, 255, 255);
  margin-right: 15px;
}

.profilepic {
  width: 50px !important;
  height: 50px;
  border-radius: 50px;
  object-fit: cover;
}

.changeBg .drophead h4,
.changeBg .drophead p {
  color: #fff;
}



/* .drophead  {
   text-align: right;
   margin-right: 10px;
} */

.drophead h4 {
  font-size: 14px;
  margin: 0px;
  font-weight: 500;
}

.drophead p {
  font-size: 11px;
  margin: 0px;
}

.dropdwon_users .btn:hover {
  color: #2f29a4;
  border: 0;
}

.dropdwon_users .btn {

  border: 0;
}

.dropdwon_users .dropdown-item {
  padding: 0.25rem 0.365rem !important;
}

.dropdwon_users .dropdown-menu {

  padding: .5rem 1rem;

  font-size: 0.765rem;
  color: #141414;
}

.dropdwon_users .bg_hover .dropdown-item:focus,
.dropdown-item:hover {
  background-color: #fff;
  color: #2f29a4 !important;
}

.dropdwon_users .dropdown-toggle::after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid;
  border-right: .3em solid transparent;
  border-bottom: 0;
  border-left: .3em solid transparent;
  display: none;
}


.header_main .navbar-light .navbar-nav .active>.nav-link,
.header_main .navbar-light .navbar-nav .nav-link.active,
.header_main .navbar-light .navbar-nav .nav-link.show,
.header_main .navbar-light .navbar-nav .show>.nav-link {
  color: rgba(220, 215, 215) !important;
  border-bottom: 1px solid #fff;
  font-weight: 600;
}

.header_main .navbar-light .navbar-nav .nav-link:hover {
  color: #ccc !important;
}


/* .header-dark-page  .header_main .navbar-light .navbar-nav .nav-link:hover {
  color: #2f29a4 !important;
} */


.header_main .btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: none;
}

header.header_main img {
  width: 140px;
}

.sec-one {
  overflow: hidden;
}

.btn.btn-white-tranparent {
  border: 2px solid #fff;
  border-radius: 50px;
  color: #fff;
  font-weight: 400;
}

.btn.btn-white {
  background-color: #fff;
  border-radius: 50px;
  color: #2e30ac;
  font-weight: 400;
}

.bg-blue-img {
  background: url("/public/assets/img/therpy/bg-main.png") no-repeat;
  height: 1050px;
  background-position-y: bottom;
  background-size: cover;
}

.width_text {
  width: 52px;
  height: 9px;
  flex-shrink: 0;
  background: #fff;
  display: block;
  margin: 0 auto;
}

.secone-wrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 100px 0px;
}

.main-title {
  color: #fff;
  font-family: Roboto;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 83.181%;
  /* 37.431px */
}

.sub-title {
  color: #fff;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 83.181%;
}

.secone-inner>*:not(:last-child) {
  margin-bottom: 20px;
  /* Adjust the margin as needed */
}

.box_card_inner {
  padding: 60px 0px;
}

.card_one {
  padding: 25px 25px;
  border-radius: 38px;
  background: #fff;
  width: 310px;
  height: 280px;
  flex-shrink: 0;
  box-shadow: 0px 10px 6.1px 0px rgba(0, 0, 0, 0.25);
  outline: 1px solid transparent;
  transition: outline 1s cubic-bezier(0.12, 0, 0.39, 0) 0s;
}

.card_one:hover {
  outline: 6px solid #2e98d9;
}

.card_one img {
  width: auto;
  height: auto;
  flex-shrink: 0;
}

.text_card {
  margin: 20px 0px;
}

.text_card h2 {
  color: #2f29a4;
  font-family: Roboto;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 83.181%;
  margin-bottom: 14px;
}

.text_card p {
  color: #000;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 83.181%;
  margin-left: 1px;
  /* 13.309px */
}

.custom-radio {
  position: relative;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
}

label.custom-radio:hover img {
  transform: scale(1.2);
  transition: all 1s ease;
}

.custom-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.radio-icon {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 30px;
  background-color: #2f29a4;
  border-radius: 50%;
}

.custom-radio input:checked+.radio-icon:after {
  content: "\2713";
  /* Checkmark Unicode character */
  font-size: 26px;
  color: #fff;
  /* Checkmark color */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.p-40 {
  padding: 40px 0px;
}

.ulclass {
  padding: 0px;
  list-style: none;
}

.img_circle img {
  width: auto;
  height: 350px;
}

.text_absoulte3 {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(110px, 120px);
  font-size: 35px;
}

.img_circle {
  position: relative;
}

.third_sec_text:not(:last-child) {
  margin-bottom: 20px;
}

.third_sec_text h5 {
  color: #2f29a4;
  font-family: Roboto;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 133.181%;
  margin: 0px;
  /* 59.931px */
}

.third_sec_text p {
  color: #000;
  font-family: Roboto;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 133.181%;

  /* 33.295px */
}

.wline {
  background: rgba(0, 0, 0, 0.18);
  width: 300px;
  height: 1px;
  display: block;
}


.textblue {
  color: #2e31a5;
  font-family: Roboto;
  font-size: 35px;
  font-style: normal;
  font-weight: 600;
  line-height: 133.181%;
}

.textwhite {
  color: #2e31a5;
  font-family: Roboto;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 133.181%;
}

.suitable_textblueline {
  margin-top: 5rem;
}

img.img-fluid.fluid2 {
  height: 155px;
}



.bg-grey-img {
  background: url("/public/assets/img/therpy/bg-grey.png") no-repeat;
  height: 800px;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: end;
}


.text-forth-head>*:not(:last-child),
.fisthsec_text>*:not(:last-child) {
  margin-bottom: 20px !important;
}

.bg-main {
  width: auto;
  background-color: #2f29a4 !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 133.181%;
}

.black-lg-text {
  color: #111;
  font-family: Roboto;
  font-size: 35px;
  font-style: normal;
  font-weight: 600;
  line-height: 133.181%;
  margin: 0px !important;
  /* 59.931px */
}

.text-p-all {
  color: #000;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 133.181%;
  margin: 0px !important;
  /* 39.954px */
}

.forth_sec {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 59px 0px;
  margin-top: 20px;
}

.light-blue-text {
  color: #2f29a4;
  font-family: Roboto;
  font-size: 35px;
  font-style: normal;
  font-weight: 400;
  line-height: 133.181%;
  /* 59.931px */
}

.lineheading {
  width: 110px;
  height: 1px;
  background: #2f29a4;
  margin: 0 auto;
}

.heading-tittle {
  color: #111;
  font-family: Roboto;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 133.181%;
  /* 59.931px */
}

.heading-top {
  padding: 80px 0px;
}

.pt5 {
  padding-top: 160px;
}

.bg-blue {
  background: linear-gradient(180deg, #2f29a4 0%, #042056 100%);
}

.profile_name h6 {
  color: #1b1b1b;
  text-align: center;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.741px;
  /* 104.167% */
}

.slider_wrapper {
  overflow: hidden;
}

.profile_name p {
  color: #c1c1c1;
  font-family: Roboto;
  font-size: 16.374px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.741px;
}

.profile_item img {
  border-radius: 50px;
  margin: 0 auto;

  margin-bottom: 15px;
}

.card-slider-box {
  width: 100%;
  height: auto;
  flex-shrink: 0;
  border-radius: 9.096px;
  background: #fff;
  box-shadow: 0px 27.28931px 549.42487px 0px rgba(28, 90, 104, 0.09);
  padding: 20px;
  margin: 0 auto;
}

.slider_slick {
  margin-bottom: 80px;
}

.slider_slick .slick-list {
  position: relative;
  display: block;
  overflow: inherit !important;
  margin: 0;
  padding: 0;
}

.p-80 {
  padding: 20px 0px 80px;
}

.sec-sixth {
  margin-top: 80px;
}

.profile_desc p {
  color: #1b1b1b;
  text-align: center;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  /* 165.385% */
}

.profile_name {
  text-align: center;
}

.p-140 {
  padding: 140px 0px;
}

.faq_text h6 {
  color: #000;
  font-family: Roboto;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  /* 120.968% */
}

.faq_text p {
  color: #000;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  /* 180% */
}

.lblue {
  color: #3489d1 !important;
}

.faq_accordian .accordion-item {
  color: var(--bs-accordion-color);
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #000;
  border-radius: 0px;
}

.faq_accordian .accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: transparent;
  box-shadow: none;
}

.faq_accordian .accordion-item:first-child {
  border-bottom: 1px solid #000;
  border-top: 1px solid #000;
}

.faq_accordian .accordion-body {
  padding-top: 0;
}

.faq_accordian .accordion-button {
  background-color: transparent !important;
}

.bg-smal {
  width: 25%;
  background-color: #2f29a4 !important;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 133.181%;
}

/* footer css */

.footer_head ul {
  list-style: none;
}

.footer_head h6 {
  font-size: 20px;
  color: #0e0e0e;
  margin-bottom: 20px;
}

.footer_head ul li {
  font-size: 15px;
  font-weight: 400;
  padding: 10px 0px 5px;
  color: #343434;
}

.footer_logo {
  height: 100%;
  text-align: center;
  display: flex;
  justify-content: start;
  align-items: center;
}

.footer_logo img {
  width: 200px;
}

.copyright_wrap p {
  font-size: 15px;
  margin: 0px;
  padding: 30px 0 20px;
}

.btn-color {
  background-color: #2f29a4 !important;
}


section {
  position: relative !important;
}



/* new css */


.img_for_gallery {
  position: relative;
  top: -40px;
}

.img_fifth_sec {
  width: 100%;
  max-width: 90%;
}

.img_fifth_sec img {
  aspect-ratio: 3/2;
  object-fit: contain;
}



.flex-mobile {
  justify-content: end;
  align-items: end;
}


.custom-next-arrow {
  background: #000;
  width: 30px;
  height: 30px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  color: #fff;
  z-index: 99;
  cursor: pointer;

}

.arrow-right {
  transform: translate(-230px, -50px);
  right: 0;
}

.arrow-left {
  left: 0;
  transform: translate(230px, -50px);

}

.slick-slide {
  transform: scale(0.8);
}

.slick-active {
  transform: scale(1);
  transition: 0.5s ease-in-out;
}


.slick-list {
  position: relative;
  display: block;
  overflow: inherit !important;
  margin: 0px 200px !important;
  padding: 0;
}


.img-social span {
  margin-right: 10px;
}

.img-social {
  margin-bottom: 15px;
}

span.new_leeter {
  color: #343434;
  font-size: 12px;
}


@media (max-width: 767px) {
  .navbar-light .navbar-toggler {
    background: #fff;
  }

  .card_one {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
    padding: 9px;
    border-radius: 18px;
  }

  .sub-title {
    font-size: 12px;
  }

  .main-title {
    font-size: 26px;
  }

  .card_one .text-left {
    text-align: left !important;
  }

  .text-left img {
    width: 60px;
    margin-bottom: 15px;
  }

  .text_card p {
    font-size: 12px;
  }

  .text_card h2 {
    font-size: 20px;
  }

  .text_card {
    margin: 0px 10px !important;
  }

  .box_card_inner {
    padding: 40px 10px;
  }

  .secone-wrapper {
    padding: 50px 0px;
  }

  header.header_main img {
    width: 100px;
  }

  .bg-blue-img {
    height: auto;
    background: linear-gradient(180deg, #2f29a4 0%, #042056 100%) !important;
  }

  .text_absoulte3 {
    position: absolute;
    top: 80px;
    font-size: 35px;
    text-align: center;
    transform: translate(6px, 13px);
  }


  .textblue {
    font-size: 35px;
  }

  .bg-grey-img {
    background: #fff;
    height: auto;
  }

  .img_circle img {
    height: auto;
  }

  .sec-third {
    position: relative !important;
    padding: 50px 0px 0px;
  }

  .sec3_ul {
    text-align: center;
  }

  .forth_sec {
    display: block;
    padding: 60px 0px;
  }

  .black-lg-text {
    font-size: 35px;
  }

  .text-p-all {
    font-size: 20px;
  }

  .bg-main {
    width: auto;
  }

  .light-blue-text {
    font-size: 35px;
  }

  .fisthsec_text {
    margin-top: 50px;
  }

  .pt5 {
    padding-top: 60px;
  }

  .flex-column-reverse-mobile {
    flex-direction: column-reverse;
  }

  .fisthsec_text.text-right {
    text-align: left !important;
  }

  .p-140 {
    padding: 60px 0px;
  }

  footer.p-60.pt-0 {
    text-align: center;
    padding: 40px 0px !important;
  }

  .footer_logo img {
    width: 200px;
    margin: 0 auto;
    margin-bottom: 50px;
  }

  .faq_text h6 {
    font-size: 35px;
  }

  .faq_text {
    margin-bottom: 30px;
    text-align: center;
  }

  .heading-tittle {
    font-size: 35px;
  }

  .heading-top {
    padding: 60px 0px;
  }

  .profile_desc p {
    font-size: 14px;
    line-height: inherit;
  }

  .card-slider-box {
    padding: 20px 10px;
    margin: 0 auto;
  }

  br {
    display: none;
  }

  .img_fifth_sec img {
    object-fit: contain;
    aspect-ratio: unset !important;
  }


  .mobile-arrow .arrow-right {
    transform: translate(-36px, -50px);
    right: 0;
  }

  .mobile-arrow .arrow-left {
    transform: translate(36px, -50px);
    right: 0;
  }

  .slider_slick .slick-list {
    margin: 0px 0px !important;
  }

  .footer_head {
    text-align: center;
  }

}


@media (min-width:768px) and (max-width:992px) {
  .card_one {
    border-radius: 28px;
    width: 210px;
    height: 260px;
  }

  .slider_slick .slick-list {
    margin: 0px 20px !important;
  }

  .navbar-light .navbar-toggler {
    background: #fff;
  }

  .ipad .arrow-right {
    transform: translate(-90px, -50px);
    right: 0;
  }

  .ipad .arrow-left {
    transform: translate(90px, -50px);
    right: 0;
  }

  .bg-blue-img {
    height: 950px;
  }

  .text_absoulte3 {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(64px, 79px);
    font-size: 25px;
  }

  .img_for_gallery {
    position: relative;
    top: 40px !important;
  }

  .bg-grey-img {
    height: 700px !important;
  }

  .faq_text p {
    font-size: 15px;
    width: 80%;
  }

  .lblue {
    font-size: 14px;
    display: inline-flex;
  }

  .bg-smal {
    width: auto;
  }

  .faq_text h6 {
    font-size: 25px;
  }

  .img_fifth_sec img {
    object-fit: contain;
    aspect-ratio: unset !important;
  }

  .forth_sec {
    padding: 50px 0px;
    margin-top: 0;
  }

  .img_circle img {
    width: auto;
    height: 250px !important;
  }

  .third_sec_text h5 {
    font-size: 25px !important;
  }

  .textblue {
    font-size: 25px;
  }

  .third_sec_text p {
    font-size: 20px;
  }

  .img_for_gallery img {
    max-width: 175px;
  }

  .flex-mobile {
    justify-content: center !important;
    align-items: center !important;
  }

  .bg-main {
    width: auto;
  }

  .text-p-all {
    font-size: 20px;
  }

  .black-lg-text {
    font-size: 25px;
  }

  .light-blue-text {
    font-size: 25px;
  }

  .img_fifth_sec {
    width: 30%;
  }

  .footer_logo {
    height: 100%;
    text-align: center;
    display: block;
  }

  .footer_logo img {
    width: 177px;
  }

}


.imgs_testi {
  width: 90px;
  height: 90px;
  object-fit: contain;
  background: #b2b2b2;
  object-position: top;
}