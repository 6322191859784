 
.Product_Datatabs li a.active {
    background: transparent !important;
    box-shadow: inherit !important;
    color: #765aa6 !important;
    font-size: 14px;
    border-bottom: 2px solid #765aa6;
    width: fit-content;
    border-radius: 0px !important;
}
.card.shadow_custom {
    margin-bottom: 1rem;
    box-shadow: 0px 3px 5px #cccccc8a;
    border-radius: 8px;
}
h6.desc_data {
    color: #222222;
    font-size: 18px;
    font-weight: 600;
}
p.general {
    // padding: 10px 11px;
    // border-bottom: 1px solid #EAECF0;
    font-size: 16px;
    font-weight: 600;
    color: #3C3E49;
    margin-bottom: 0px;
    display: flex;
}
.heading_details {
    padding: 15px 15px;
    border-bottom: 1px solid #EAECF0;
    display: flex;
    align-items: center;
}
h2.pro_heading {
    font-size: 22px;
    font-weight: 600;
    color: #344054;
}
p.text_general {
    font-size: 12px;
    color: #6B7280;
    margin-bottom: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}
.flexttext{
    flex-wrap: wrap;
}
.layout_details {
    max-width: 275px;
}
.Product_Datatabs {
    border-bottom: 1px solid #E8E8E8;
}
.productImageShine{
    height: 300px;
}
img.rounded-4.fit.w-100 {
    height: 120px;
    object-fit: cover;
    border-radius: 4px !important;
}
h4.title.textdark {
    font-size: 16px;
    color: #202020 !important;
    font-weight: 600;
}
.detail_data{
    font-size: 12px;
    color: #84818A;
    width: 100%;
    display: flex;
}
.detail_data span {
    color: #202020;
    font-weight: 500;
}
.Product_Datatabs li a {
    width: fit-content !important;
    color: #84818A;
}
p.catCls {
    color: #636363;
    margin-bottom: 0px;
}
.row.product_location .col-md-8 {
    color: #585858 !important;
    font-size: 15px;
}
.row.product_location {
    line-height: 2;
}
aside.col-lg-6.position-sticky {
    top: 10px !important;
    display: block;
    height: 100%;
}





.cardHeader .mb-0 > a {
    display: block;
    position: relative;
  }
  
  .cardHeader .mb-0 > a:after {
    content: "\f067";
    font-family: "Font Awesome 5 Free";
    position: absolute;
    right: 0;
    font-weight: 600;
  }
  
  .cardHeader .mb-0 > a[aria-expanded="true"]:after {
    content: "\f068";
    font-family: "Font Awesome 5 Free";
    font-weight: 600;
  }
  .pricing span {
    border: 1px solid #7661a3;
    padding: 3px 7px;
    border-radius: 5px;
}
.table-responsive.table_section.pl-0 {
    padding: 0px !important;
}
h4.locationCls.mt-2 {
    font-size: 18px;
}

.fc-direction-ltr{
    min-height:550px;
}
