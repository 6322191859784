
.contact-us {
  background:#000;
 padding: 50px 1px;
}

.contact-us-bg {
  text-align: center;
  padding: 60px 0;
}

.address-inner {
  display: flex;
  justify-content: center;
  align-items: center;
}
.address-inner p {
    font-size: 18px;
    font-weight: 500;
}

.address-inner h3 {
    margin-bottom: 0px;
    font-weight: 500;
    font-size: 15px;
}

.address-icon {
    background: #2f29a4;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin: 0 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.address-icon i {
   color: #fff !important;
}


.address-fields {
  margin: 50px 0;
}

.address-inner h3 {
  font-size: 20px;
  color: #222;
}

.address-inner p {
  margin: 0;
  color: #222;
  font-size: 14px;
}

.contact-us-title-inner {
  text-align: center;
  margin: 30px 0 40px 0;
}

.contact-us-title-inner p {
  color: #222;
  margin: 0;
}

.contact-us-inner {
  border-radius: 10px;
  max-width: 60%;
  margin: 0 auto 50px;
  background: #fff3f3;
  padding: 30px;
}




.faq_inner .accordion-button {
   border: 1px solid #babcf5;
}


/* reviews  start */





.testimonial-area .owl-carousel {
	overflow: hidden;
	padding: 0 20px;
	margin: 0px -40px;
	padding-right: 40px;
}

.single-testimonial {
	border: 2px solid #2f29a4;
	text-align: center;
	border-radius: 45px;
	position: relative;
	z-index: 2;
}
.single-testimonial p {
	color: #141414;
	font-size: 15px;
	line-height: 24px;
	padding: 50px;
	padding-bottom: 30px;
	position: relative;
	z-index: 3;
}
.single-testimonial::before {
	content: "";
	position: absolute;
	left: -12px;
	top: -35px;
	background: url("../../../public/assets/img/therpy/question-black.png") no-repeat ;
	background-size: 60%;
	width: 100px;
	height: 80px;
	transform: rotate(180deg);
	background-position: 34px 15px;
     background-color: #fff;
}
.single-testimonial::after {
	content: "";
	position: absolute;
	right: -12px;
	bottom: -34px;
	background: url("../../../public/assets/img/therpy/question-black.png") no-repeat ;
	background-size: 60%;
	width: 100px;
	height: 80px;
	background-position: 34px 19px;
    background-color: #fff;
}

.client-video {
	padding-right: 15px;
}
.client-info {
	position: relative;
	z-index: 3;
}
.client-info a {
	width: 40px;
	height: 40px;
	border-radius: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: 0 0 16px rgba(0, 0, 0, 0.16);
	font-size: 22px;
}
.client-info {
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: left;
	padding-bottom: 50px;
}
.client-info h6 {
	color: #000;
	font-weight: 700;
	font-size: 18px;
	color: #141414;
}
.client-info span {
	display: inline-block;
	color: #141414;
	font-size: 12px;
}
.sec-title.white-title h2 {
	color: #141414;
}



@media (max-width:767px){
    .address-inner {
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: 25px;
    flex-direction: column;
    text-align: center;
}
.address-icon{
    margin-bottom: 10px;
}

.footerarea.d-flex.justify-content-between.align-items-center {
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}
}