img.paymentLogo {
    width: 200px;
    object-fit: contain;
}
 .bg-gray {
    background: #f3f3f3;
}
i.fa.fa-info-circle.textlight.mr-1 {
    color: #8a8a8a;
}
.rounded {
border-radius: 8px;
}
p.d-block.userHours.active {
    color: #0063dc;
    width: fit-content;
    font-size: 12px;
    font-weight: 600;
    background: #a5b4ff3d;
}